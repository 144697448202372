import { forwardRef, useRef, useState } from "react";
import { Description, FormContainer, ImgEtapa, ImgLogo, Label } from "../commons";
import { Form, Row } from "react-bootstrap";
import LogoFull from "../../../Images/logo_full.png"
import LogoEtapa from "../../../Images/cadastrando_anamnese.png"
import { Input } from "../../../components/Input";
import { VbCheckBox } from "../../../components/VbCheckBox";
import { VbFormLabel } from "../../../components/VbControls";
import { VbButton } from "../../../components/VbButton";
import { useAuth } from "../../../contexts/AuthProvider/useAuth";
import { toast } from "react-toastify";
import Body from "../../../Images/body.png";
import { toastError } from "../../../commons/utils";

export const AnamensePub = forwardRef((props: any, ref) => {
    const auth = useAuth();

    const objetivoRef = useRef<any>();
    const detalheLimitacaoMovRef = useRef<any>();
    const cirurgiaRef = useRef<any>();
    const tempoParadoRef = useRef<any>();
    const livreRef = useRef<any>();

    const tonturaRef = useRef<any>();
    const desmaiosRef = useRef<any>();
    const escurecimentoRef = useRef<any>();
    const dornopeitoRef = useRef<any>();
    const taquicardiaRef = useRef<any>();
    const faltadearRef = useRef<any>();

    const tatoRef = useRef<any>();
    const visualRef = useRef<any>();
    const auditivaRef = useRef<any>();

    const fisioRef = useRef<any>();
    const pilatesRef = useRef<any>();
    const tempoFisioRef = useRef<any>();
    const motivoFisioRef = useRef<any>();
    const doresRef = useRef<any>();
    const gestanteRef = useRef<any>();

    const [validated, setValidated] = useState(false);
    const [loading, setLoading] = useState<any>(false);

    const [cardio, setCardio] = useState<any>();
    const [hipertensao, setHipertensao] = useState<any>();
    const [colesterol, setColesterol] = useState<any>();
    const [remedioContinuo, setRemedioContinuo] = useState<any>();
    const [limiteMovimento, setLimiteMovimento] = useState<any>();
    const [medicoRegular, setMedicoRegular] = useState<any>();
    const [exercicio, setExercicio] = useState<any>();
    const [perfeccionista, setPerfeccionista] = useState<any>();
    const [seuJeito, setSeuJeito] = useState<any>();
    const [tipoPessoa, setTipoPessoa] = useState<any>();
    const [execucao, setExecucao] = useState<any>();
    const [termo, setTermo] = useState<any>();
    const [dores, setDores] = useState<any>();
    const [fisio, setFisio] = useState<any>();
    const [pilates, setPilates] = useState<any>();

    async function upsert() {
        const params: any = new URLSearchParams(window.location.search);

        async function execute() {
            let _data = {
                anamnid: 0,
                anamnobjetivo: objetivoRef.current.getValue(),
                anamnsintomas: tonturaRef.current.getValue() +
                    (desmaiosRef.current.getValue() * 2) +
                    (escurecimentoRef.current.getValue() * 4) +
                    (dornopeitoRef.current.getValue() * 8) +
                    (taquicardiaRef.current.getValue() * 16) +
                    (faltadearRef.current.getValue() * 32),
                anamncardio: cardio,
                anamnhiperarterial: hipertensao,
                anamncolesterol: colesterol,
                anamnremediocontinuo: remedioContinuo,
                anamnlimitemovimento: limiteMovimento,
                anamnlimitemovimentodesc: detalheLimitacaoMovRef?.current?.getValue() ?? '',
                anamncirurgia: cirurgiaRef.current.getValue(),
                anamnmedicofrequente: medicoRegular,
                anamnpraticaexercicio: exercicio,
                anamntempoparado: tempoParadoRef.current.getValue(),
                anamnperfeccionista: perfeccionista,
                anamnfazersuamaneira: seuJeito,
                anamntipopessoa: tipoPessoa,
                anamnindependente: execucao,
                anamnformaaprendizado: tatoRef.current.getValue() +
                    (visualRef.current.getValue() * 2) +
                    (auditivaRef.current.getValue() * 4),
                anamncomplemento: livreRef.current.getValue(),
                anamnfisio: fisioRef.current.getValue() + (pilatesRef.current.getValue() * 2),
                anamnfisiotempo: tempoFisioRef?.current?.getValue() ?? '',
                anamnfisiomotivo: motivoFisioRef?.current?.getValue() ?? '',
                anamnfisiodores: dores,
                anamnfisiolocal: doresRef?.current?.getValue() ?? '',
                anamngestante: gestanteRef.current.getValue(),
                prospect: {
                    prosptoken: params.get("u_prospect")
                }
            };

            setLoading(true);

            await auth.post('anamnese', _data).then(() => {
                toast.success('Anamnese enviada com sucesso.');
                window.location.replace('/success-form');
            }).catch((error: any) => {
                toastError(error)
            }).finally(() => {
                setLoading(false);
            })
        }

        execute();
    }

    const handleSubmit = (e: any) => {
        const form = e.currentTarget;

        if (form.checkValidity() === true) {
            if (termo === 0)
                toast.error('Termo de responsabilidade não foi aceito.')
            else
                upsert()
        } else
            setValidated(true);

        e.preventDefault();
        e.stopPropagation();
    };

    return (
        <FormContainer className="d-flex flex-column">
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <Row className="mb-3 justify-content-center">
                    <ImgLogo src={LogoFull} />
                    <Row className="justify-content-center">
                        <ImgEtapa src={LogoEtapa} />
                    </Row>
                    <Row className="mb-3 justify-content-center">
                        <Label>ANAMNESE</Label>
                    </Row>
                    <Description>Por favor, responda o questionário abaixo com sinceridade...</Description>
                </Row>
                <fieldset disabled={loading}>
                    <Row className="mb-4 justify-content-center">
                        <Input ref={objetivoRef} id="objetivo" type="text" label="Qual é o seu objetivo ao procurar a VBFit?" textarea required rows={5} maxLength={1500} />
                    </Row>
                    <Row className="mb-4 justify-content-center">
                        <VbFormLabel>No dia a dia você costuma sentir:</VbFormLabel>
                        <VbCheckBox ref={tonturaRef} id="tontura" label="Tontura" />
                        <VbCheckBox ref={desmaiosRef} id="desmaios" label="Desmaios" />
                        <VbCheckBox ref={escurecimentoRef} id="escurecimento" label="Escurecimento das vistas" />
                        <VbCheckBox ref={dornopeitoRef} id="dornopeito" label="Dor no peito" />
                        <VbCheckBox ref={taquicardiaRef} id="taquicardia" label="Taquicardia" />
                        <VbCheckBox ref={faltadearRef} id="faltadear" label="Falta de ar" />
                    </Row>
                    <Row className="mb-4 justify-content-center">
                        <VbFormLabel>Você tem alguma doença cardiovascular?</VbFormLabel>
                        <VbCheckBox id="cardiosim" group="cardio" type="radio" label="Sim" onClick={() => setCardio(1)} />
                        <VbCheckBox id="cardionao" group="cardio" type="radio" label="Não" onClick={() => setCardio(0)} />
                    </Row>
                    <Row className="mb-4 justify-content-center">
                        <VbFormLabel>Você tem hipertensão arterial?</VbFormLabel>
                        <VbCheckBox id="hipertensaosim" group="hipertensao" type="radio" label="Sim" onClick={() => setHipertensao(1)} />
                        <VbCheckBox id="hipertensaonao" group="hipertensao" type="radio" label="Não" onClick={() => setHipertensao(0)} />
                    </Row>
                    <Row className="mb-4 justify-content-center">
                        <VbFormLabel>Você tem colesterol alto?</VbFormLabel>
                        <VbCheckBox id="colesterolsim" group="colesterol" type="radio" label="Sim" onClick={() => setColesterol(1)} />
                        <VbCheckBox id="colesterolnao" group="colesterol" type="radio" label="Não" onClick={() => setColesterol(0)} />
                    </Row>
                    <Row className="mb-4 justify-content-center">
                        <VbFormLabel>Você faz uso de algum remédio contínuo?</VbFormLabel>
                        <VbCheckBox id="remediocontsim" group="remediocontinuo" type="radio" label="Sim" onClick={() => setRemedioContinuo(1)} />
                        <VbCheckBox id="remediocontnao" group="remediocontinuo" type="radio" label="Não" onClick={() => setRemedioContinuo(0)} />
                    </Row>
                    <Row className="mb-4 justify-content-center">
                        <VbFormLabel>Você possui alguma lesão, ou sente dor em alguma articulação com frequência?</VbFormLabel>
                        <VbCheckBox id="limitemovimentosim" group="limitemovimento" type="radio" label="Sim" onClick={() => setLimiteMovimento(1)} />
                        <VbCheckBox id="limitemovimentonao" group="limitemovimento" type="radio" label="Não" onClick={() => setLimiteMovimento(0)} />
                    </Row>
                    {limiteMovimento ?
                        <Row className="mb-4 justify-content-center">
                            <Input ref={detalheLimitacaoMovRef} id="detalhelimitacao" type="text" textarea label="(Sobre a pergunta acima) Qual Articulação? Qual teor de dor, de 0 a 10?" rows={5} maxLength={1500} />
                        </Row> : <></>
                    }
                    <Row className="mb-4 justify-content-center">
                        <Input ref={cirurgiaRef} id="cirurgia" type="text" textarea label="Já fez algum tipo de cirurgia? Se sim, qual?" rows={5} maxLength={1500} />
                    </Row>
                    <Row className="mb-4 justify-content-center">
                        <VbFormLabel>Você vai regularmente ao médico?</VbFormLabel>
                        <VbCheckBox id="medicoregularsim" group="medicoregular" type="radio" label="Sim" onClick={() => setMedicoRegular(1)} />
                        <VbCheckBox id="medicoregularnao" group="medicoregular" type="radio" label="Não" onClick={() => setMedicoRegular(0)} />
                    </Row>
                    <Row className="mb-4 justify-content-center">
                        <VbFormLabel>Você já fez ou faz algum tipo de exercício físico?</VbFormLabel>
                        <VbCheckBox id="exerciciosim" group="exercicio" type="radio" label="Sim" onClick={() => setExercicio(1)} />
                        <VbCheckBox id="exercicionao" group="exercicio" type="radio" label="Não" onClick={() => setExercicio(0)} />
                    </Row>
                    <Row className="mb-4 justify-content-center">
                        <Input ref={tempoParadoRef} id="tempoparado" type="text" label="Quanto tempo está parado (a)? / ou / Qual exercício você fez?" textarea required rows={5} maxLength={1500} />
                    </Row>
                    <Row className="mb-4 justify-content-center">
                        <VbFormLabel>Você se considera uma pessoa perfeccionista?</VbFormLabel>
                        <VbCheckBox id="perfeccionistasim" group="perfeccionista" type="radio" label="Sim" onClick={() => setPerfeccionista(1)} />
                        <VbCheckBox id="perfeccionistanao" group="perfeccionista" type="radio" label="Não" onClick={() => setPerfeccionista(0)} />
                    </Row>
                    <Row className="mb-4 justify-content-center">
                        <VbFormLabel>Você gosta de fazer as coisas do seu jeito, da sua maneira, no seu tempo e na sua hora?</VbFormLabel>
                        <VbCheckBox id="seujeitosim" group="seujeito" type="radio" label="Sim" onClick={() => setSeuJeito(1)} />
                        <VbCheckBox id="seujeitonao" group="seujeito" type="radio" label="Não" onClick={() => setSeuJeito(0)} />
                    </Row>
                    <Row className="mb-4 justify-content-center">
                        <VbFormLabel>Você é uma pessoa:</VbFormLabel>
                        <VbCheckBox id="tipopessoa1" group="tipopessoa" type="radio" label="mais introvertida, retraída, tímida, pouco sociável" onClick={() => setTipoPessoa(1)} />
                        <VbCheckBox id="tipopessoa2" group="tipopessoa" type="radio" label="extrovertida, comunicativa, desinibida, mais sociável" onClick={() => setTipoPessoa(2)} />
                    </Row>
                    <Row className="mb-4 justify-content-center">
                        <VbFormLabel>Você se percebe ser uma pessoa mais independente para executar as tarefas ou dependente, um pouco insegura?</VbFormLabel>
                        <VbCheckBox id="execucao1" group="execucao" type="radio" label="uma pessoa mais independente para executar as tarefas" onClick={() => setExecucao(1)} />
                        <VbCheckBox id="execucao2" group="execucao" type="radio" label="mais dependente, um pouco insegura" onClick={() => setExecucao(2)} />
                    </Row>
                    <Row className="mb-4 justify-content-center">
                        <VbFormLabel>Normalmente, como você aprende: Visual (ver para entender), auditivo (comando de voz) ou tato (qual músculo está trabalhando)?</VbFormLabel>
                        <VbCheckBox ref={tatoRef} id="tato" group="aprendizado" label="Tato" />
                        <VbCheckBox ref={visualRef} id="visual" group="aprendizado" label="Visual" />
                        <VbCheckBox ref={auditivaRef} id="auditiva" group="aprendizado" label="Auditiva" />
                    </Row>
                    <Row className="mb-4 justify-content-center">
                        <Input ref={gestanteRef} id="gestante" type="text" label="É gestante? Se sim, há quantas semanas?" maxLength={100} />
                    </Row>
                    <Row className="mb-4 justify-content-center">
                        <VbFormLabel>Você já fez Fisioterapia e/ou Pilates?</VbFormLabel>
                        <VbCheckBox ref={fisioRef} id="fisioterapia" label="Fisioterapia" onClick={() => setFisio(!fisio)} />
                        <VbCheckBox ref={pilatesRef} id="pilates" label="Pilates" onClick={() => setPilates(!pilates)} />
                    </Row>
                    {
                        (fisio || pilates) ?
                            <>
                                <Row className="mb-4 justify-content-center">
                                    <Input ref={tempoFisioRef} id="tempofisio" type="text" label="Por quanto tempo fez Fisioterapia e/ou Pilates??" maxLength={20} />
                                </Row>
                                <Row className="mb-4 justify-content-center">
                                    <Input ref={motivoFisioRef} id="motivofisio" type="text" label="Por qual motivo fez Fisioterapia e/ou Pilates?" textarea rows={2} maxLength={100} />
                                </Row>
                            </> : <></>
                    }
                    <Row className="mb-4 justify-content-center">
                        <VbFormLabel>Você sente dores?</VbFormLabel>
                        <VbCheckBox id="doressim" group="dores" type="radio" label="Sim" onClick={() => setDores(1)} />
                        <VbCheckBox id="doresnao" group="dores" type="radio" label="Não" onClick={() => setDores(0)} />
                    </Row>
                    {
                        dores === 1 ?
                            <>
                                <Row className="mb-4 justify-content-center">
                                    <img src={Body} height={'375px'} style={{ maxWidth: '375px' }} alt="Body Color" />
                                </Row>
                                <Row className="mb-4 justify-content-center">
                                    <Input ref={doresRef} id="descdores" type="text" label="Com base na imagem acima, informe as cores em que sente dor:" textarea rows={2} maxLength={300} />
                                </Row>
                            </> : <></>
                    }
                    <Row className="mb-4 justify-content-center">
                        <Input ref={livreRef} id="textoaberto" type="text" label="Fique a vontade para escrever o que quiser" textarea rows={5} maxLength={1500} />
                    </Row>
                    <Row className="mb-4 justify-content-center">
                        <VbFormLabel style={{ fontSize: '1.150rem' }}>TERMO DE RESPONSABILIDADE</VbFormLabel>
                        <Input id="termos" type="text" textarea rows={10} maxLength={1500} disabled defaultValue={`É de grande importância a consulta ao seu médico, bem como a realização de exames complementares, (como eletrocardiograma e outros), antes de praticar qualquer atividade física, tendo em vista os riscos que envolvem esta prática. Declaração: Declaro ao finalizar esse questionário estar ciente dos termos acima, bem como estar em plenas condições de saúde. Além disso, declaro ter passado ao professor, todas as informações relativas à minha saúde, tais como limitações, lesões e dores que sinto. Assim sendo, assumo total responsabilidade pelo meu estado de saúde, isentando a VB Fit sobre qualquer acontecimento dentro de suas dependências. Em caso de acidente, ou qualquer outra situação que gere risco para a minha saúde, dentro das instalações do estúdio, quando houver necessidade de socorros médicos, autorizo a VB Fit a realizar os primeiros socorros e o devido encaminhamento, (incluindo remoção profissional, caso necessário) e assumo desde já, única e exclusivamente, a responsabilidade por todas as despesas médicas e hospitalares geradas.`} />
                        <VbCheckBox id="termosim" group="termo" type="radio" label="Sim" onClick={() => setTermo(1)} />
                        <VbCheckBox id="termonao" group="termo" type="radio" label="Não" onClick={() => setTermo(0)} />
                    </Row>
                    <Row className="mt-3 mb-4 justify-content-center" style={{ padding: '0 8px' }}>
                        <VbButton type="submit" size={undefined} style={{ justifyContent: 'center' }}>ENVIAR</VbButton>
                    </Row>
                </fieldset>
            </Form>
        </FormContainer >
    )
})