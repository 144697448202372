import { forwardRef, useImperativeHandle, useRef } from "react";
import { toast } from "react-toastify";
import { useAuth } from "../../../../contexts/AuthProvider/useAuth";
import { VbModal } from "../../../../components/VbModal";
import FilePresentIcon from '@mui/icons-material/FilePresent';
import FileUploader from "../../../../components/FileUploader";

export const AnexarArquivoTemplate = forwardRef((props: any, ref) => {
    const auth = useAuth();

    var contador: any;

    const modalref = useRef<any>();
    const fileRef = useRef<any>();

    function openModal(data: any) {
        contador = data.templid;
        modalref.current.openModal();
    }

    useImperativeHandle(ref, () => ({
        openModal: openModal
    }));

    async function salvarClick() {
        let request: any = {};
        var doc = fileRef.current.getFileInfo();

        async function submit() {
            await auth.post('/template/salvarArquivo', request)
                .then(() => {
                    toast.success('Arquivo anexado com sucesso.');
                    modalref.current.closeModal();
                    props.onAfterSave();
                })
                .catch((error: any) => {
                    toast.error(error?.response?.data?.mensagem ? error?.response?.data?.mensagem : 'Erro ao concluir a operação.');
                });
        }

        if (!doc)
            toast.error('Necessário selecionar uma imagem.')
        else {
            request.templid = contador;
            request.arquivo = {
                formato: doc.extension,
                descricao: doc.name,
                base64: doc.base64
            }

            try {
                modalref.current.setLoading(true);
                await submit();
            } finally {
                modalref.current.setLoading(false);
            }
        }
    }

    return (
        <VbModal ref={modalref} title={'Anexar Arquivo Template'} icon={<FilePresentIcon />} success={() => salvarClick()}>
            <FileUploader ref={fileRef} />
        </VbModal>
    )
})