import { forwardRef, useImperativeHandle, useRef, useState } from "react";
import { VbModal } from "../VbModal";
import { FaPlus } from "react-icons/fa6";
import { Col, Form, Row, Tab, Tabs } from "react-bootstrap";
import styled from "styled-components";
import { useAuth } from "../../contexts/AuthProvider/useAuth";
import { toast } from "react-toastify";
import { onValidate, toastError } from "../../commons/utils";
import { VbControls } from "../VbControls";

const VbCol = styled(Col)`
    padding-right: .5rem;
    padding-left: .5rem;
    margin-bottom: .5rem;
`;

export const CadastroPadrao = forwardRef((props: any, ref) => {
    const auth = useAuth();
    const inputsEls = useRef<{ [key: string]: any }>({});
    const refModal = useRef<any>();
    const [validated, setValidated] = useState(false);
    const [data, setData] = useState<any>();
    const [loading, setLoading] = useState<any>(false);
    const [activeTab, setActiveTab] = useState<string>();

    const inputs = props.model.inputs;

    const hasTabs = inputs.some((input: any) => input.tab);

    const renderInput = (input: any, i: number) => (
        <Form.Group as={VbCol} md={input.md} key={i}>
            <VbControls
                ref={(e: any) => inputsEls.current[input.id] = e}
                id={input.id}
                name={input.id}
                type={input.type}
                label={input.label}
                disabled={input.disabled}
                required={input.required}
                maxLength={input.maxLength}
                defaultValue={data ? getDataValue(input.id) : input.defaultValue}
                defaultEmpId={data && (input.model?.hasEmpresa) ? getEmpId(input.id) : 0}
                options={input.options}
                model={input.model}
                validated={validated}
                textarea={input.textarea}
                rows={input.rows}
                params_required={input.params_required}
                onAfterChange={(_newValue: any) => { input.onAfterChange && input.onAfterChange(_newValue, inputsEls) }}
                isMulti={input.isMulti}
            />
        </Form.Group>
    );

    const tabs: { [key: string]: JSX.Element[] } = {};
    if (hasTabs) {
        inputs.forEach((input: any, i: number) => {
            const tabKey = input.tab || "default";
            if (!tabs[tabKey]) {
                tabs[tabKey] = [];
            }

            tabs[tabKey].push(renderInput(input, i));
        });
    }

    function openModal(_data: any) {
        setActiveTab('0');
        setData(_data);
        setValidated(false);
        setLoading(false);
        refModal.current.setLoading(false);
        refModal.current.openModal();
    }

    useImperativeHandle(ref, () => ({
        openModal
    }));

    function getDataValue(id: any) {
        const idParts = id.split('.');

        if (idParts?.length === 2) {
            const [firstKey, secondKey] = idParts;
            return data[firstKey][secondKey];
        } else
            return data[id];
    }

    function getEmpId(id: any) {
        const idParts = id.split('.');
        const [firstKey] = idParts;
        return data[firstKey]?.empresa?.empid;
    }

    async function getValues() {
        const result: any = data ? { ...data } : { ...props.model.base };

        if (inputsEls) {
            await Promise.all(
                Object.values(inputsEls.current).map(async (input: any) => {
                    const inputId = input.getId();

                    if (inputId !== props.model.key) {
                        const idParts = inputId.split('.');

                        if (idParts?.length === 2) {
                            const [firstKey, secondKey] = idParts;

                            if (input.getModel().hasEmpresa)
                                result[firstKey] = {
                                    [secondKey]: await input.getValue(), 
                                    empresa: {
                                        empid: await input.getEmpId(), 
                                    },
                                };
                            else
                                result[firstKey][secondKey] = await input.getValue(); 

                        } else
                            result[inputId] = await input.getValue(); 
                    }
                })
            );
        }

        return result;
    }

    function upsert() {
        async function execute() {
            const values = await getValues();

            setLoading(true);
            refModal.current.setLoading(true);

            await auth.post(props.model.api_post, values).then((response: any) => {
                toast.success('Registro ' + (values[props.model.key] > 0, 'atualizado', 'cadastrado') + ' com sucesso.');

                refModal.current.closeModal();

                if (props.success)
                    props.success(response);
            }).catch((error: any) => {
                toastError(error);
                setLoading(false);
                refModal.current.setLoading(false);
            });
        }

        execute();
    }

    function confirmarClick() {
        setValidated(true);

        if (onValidate(inputsEls))
            upsert();
    }

    return (
        <VbModal
            ref={refModal}
            icon={<FaPlus />}
            title={props.model.name}
            size={props.model.size}
            success={() => confirmarClick()}
            customheader={props.customheader}
        >
            <Form noValidate validated={validated}>
                <fieldset disabled={loading}>
                    {hasTabs ? (
                        <Tabs
                            id="cadastro-padrao-tabs"
                            activeKey={activeTab}
                            onSelect={(k: any) => setActiveTab(k)}
                            className="mb-3"
                        >
                            {Object.keys(tabs).map((tabKey, idx) => (
                                <Tab eventKey={idx.toString()} title={tabKey} key={idx}>
                                    <Row>{tabs[tabKey]}</Row>
                                </Tab>
                            ))}
                        </Tabs>
                    ) : (
                        <Row>
                            {inputs.map((input: any, i: number) => renderInput(input, i))}
                        </Row>
                    )}
                </fieldset>
            </Form>
        </VbModal>
    );
});
