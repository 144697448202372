import { ConsultaPadrao } from "../../../components/ConsultaPadrao";
import { LabelDataTable } from "../../../components/DataTable";
import { model_instancia } from "../../../models";
import { COLOR_GREEN, COLOR_ORANGE, COLOR_RED } from "../../../theme/styles";
import QrCodeIcon from '@mui/icons-material/QrCode';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import ForumIcon from '@mui/icons-material/Forum';
import { toastError } from "../../../commons/utils";
import { useRef } from "react";
import { QrCode } from "./QrCode";
import { MensagemTeste } from "./MensagemTeste";
import { confirm } from "../../../components/VbConfirmation";
import { useAuth } from "../../../contexts/AuthProvider/useAuth";
import { toast } from "react-toastify";

export const Instancia = () => {
    const auth = useAuth();

    const refconsulta = useRef<any>();
    const qrCodeRef = useRef<any>();
    const msgTesteRef = useRef<any>();

    function labelSituacao(situacao: any) {
        return (
            <LabelDataTable color={situacao === 0 ? COLOR_RED : situacao === 1 ? COLOR_GREEN : COLOR_ORANGE} >
                {situacao === 0 ? 'Desconectado' : situacao === 1 ? 'Conectado' : 'Verificando...'}
            </LabelDataTable >
        )
    }

    function onAfterConnect() {
        refconsulta.current.refresh();
    }

    const columns = [
        { field: 'instid', header: 'Código' },
        { field: 'instdescricao', header: 'Descrição' },
        { field: 'insttipo', header: 'Tipo', body: (e: any) => e.insttipo === 1 ? 'Painel' : 'Usuário' },
        { field: 'bitsafira.situacao', header: 'Situação', body: (e: any) => labelSituacao(e.bitsafira.situacao), style: { width: '125px' } },
        { field: 'functions', style: { minWidth: '3rem', width: '3rem' } }
    ];

    function conectarInstancia(row: any) {
        console.log(row)
        if (row.bitsafira.situacao === 1)
            toast.error('Instância já está conectada.');
        else
            qrCodeRef.current.openModal(row.instid);
    }

    async function desconectar(instid: any) {
        try {
            const response: any = await auth.post('instancia/desconectar', { instid });
            refconsulta.current.refresh();
            toast.success(response.data.mensagem);
        } catch (error: any) {
            toastError(error);
        };
    }

    async function desconectarInstancia(row: any) {
        if (row.bitsafira.situacao === 0)
            toast.error('Instância já está desconectada.')
        else if (await confirm('Deseja mesmo desconetar a instância?'))
            desconectar(row.instid)
    }

    function enviarMsgTeste(row: any) {
        msgTesteRef.current.openModal(row.instid);
    }

    function functions() {
        return ([
            { label: 'Conectar Instância', icon: <QrCodeIcon />, click: conectarInstancia, color: '#14B8A6' },
            { label: 'Desconectar Instância', icon: <PowerSettingsNewIcon />, click: desconectarInstancia, color: '#EF4444' },
            { label: 'Enviar Mensagem de Teste', icon: <ForumIcon />, click: enviarMsgTeste, color: '#3B82F6' }
        ])
    }

    return (
        <>
            <ConsultaPadrao
                ref={refconsulta}
                disableEdit
                functions={functions}
                model={model_instancia}
                columns={columns}
            />
            <QrCode
                ref={qrCodeRef}
                afterConnect={onAfterConnect}
            />
            <MensagemTeste
                ref={msgTesteRef}
            />
        </>
    )
}