import { ConsultaPadrao } from "../../../../components/ConsultaPadrao";
import { model_privilegio } from "../../../../models";
import SettingsIcon from '@mui/icons-material/Settings';
import { ConfiguracaoPrivilegio } from "./ConfiguracaoPrivilegio";
import { useRef } from "react";

export const Privilegio = () => {
    const refConfiguracoes = useRef<any>();

    const columns = [
        { field: 'privid', header: 'Código' },
        { field: 'privdescricao', header: 'Descrição' },
        { field: 'functions', style: { minWidth: '7rem', width: '7rem' } }
    ];

    function functions(row: any) {
        return ([
            {
                label: 'Configurar Privilégios',
                click: () => refConfiguracoes.current.openModal(row.privid),
                icon: <SettingsIcon />,
                color: '#8a39c0'
            }
        ])
    }

    return (
        <>
            <ConsultaPadrao model={model_privilegio} columns={columns} functions={functions} />
            <ConfiguracaoPrivilegio ref={refConfiguracoes} />
        </>
    )
}