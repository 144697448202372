import { forwardRef, useImperativeHandle, useRef, useState } from "react";
import { Form, Row, Col } from "react-bootstrap";
import { VbModal } from "../../../components/VbModal";
import { toast } from "react-toastify";
import { Input } from "../../../components/Input";
import { RiBodyScanFill } from "react-icons/ri";
import { model_usuario, model_usuario_presenca } from "../../../models";
import { VbControls } from "../../../components/VbControls";
import { useAuth } from "../../../contexts/AuthProvider/useAuth";
import { isMobileDevice, toastError } from "../../../commons/utils";

interface Exercicio {
    exercid: number;
    exercdescricao: string;
    exercinformapeso: number;
    exercinformarepeticao: number;
    exercinformaflexibilidade: number;
}

interface ValoresExercicio {
    exercid: number;
    exercinformapeso: string;
    exercinformarepeticao: string;
    exercinformaflexibilidade: string;
}

interface InputMetricaProps {
    id: number;
    label: string;
    onChange: (value: string) => void;
    disabled: boolean;
}

const InputMetrica = ({ id, label, onChange, disabled }: InputMetricaProps) => (
    <Col xs={isMobileDevice() ? 4 : 3}>
        <Input id={label + id} type="number" label={label} disabled={disabled} required={!disabled} onAfterChange={(e: any) => onChange(e)} />
    </Col >
);

export const InformarMetricas = forwardRef((props: any, ref) => {
    const auth = useAuth();
    const refModal = useRef<any>();
    const refProfessores = useRef<any>();

    const [exercicios, setExercicios] = useState<Exercicio[]>([]);
    const [dataAgenda, setDataAgenda] = useState<any>();
    const [valores, setValores] = useState<ValoresExercicio[]>([]);

    function openModal(_data: any, _exercicios: Exercicio[]) {
        const exerciciosFiltrados = _exercicios?.filter(exercicio =>
            exercicio.exercinformapeso === 1 ||
            exercicio.exercinformarepeticao === 1 ||
            exercicio.exercinformaflexibilidade === 1
        );

        setDataAgenda(_data);

        if (exerciciosFiltrados) {
            setExercicios(exerciciosFiltrados);
            setValores(
                exerciciosFiltrados?.map((exercicio) => ({
                    exercid: exercicio.exercid,
                    exercinformapeso: '',
                    exercinformarepeticao: '',
                    exercinformaflexibilidade: ''
                }))
            );
        }

        refModal.current.openModal(true);
    }

    async function apply() {
        if (validarCamposObrigatorios()) {
            var body;

            if (valores && valores.length > 0) {
                body = {
                    ...dataAgenda,
                    exercicios: valores,
                };

                try {
                    await auth.post('/prospectMetrica', body);
                } catch (error) {
                    toastError(error);
                    return;
                }
            }

            body = {
                agendaid: dataAgenda.agendaid,
                agendapresente: 1,
                usuid: refProfessores.current.getValue()
            };

            auth.post(model_usuario_presenca.api_post, body)
                .then((response: any) => {
                    props.success(response);
                    refModal.current.closeModal();
                })
                .catch((error: any) => toastError(error));
        } else {
            toast.error("Preencha todos os campos.");
        }
    }

    function handleChange(index: number, field: keyof ValoresExercicio, value: string) {
        const novosValores = [...valores];
        (novosValores[index][field] as string) = value;
        setValores(novosValores);
    }

    function validarCamposObrigatorios(): boolean {
        let isValid = true;

        if (exercicios)
            exercicios.forEach((exercicio, index) => {
                if ((exercicio.exercinformapeso === 1 && !valores[index].exercinformapeso) ||
                    (exercicio.exercinformarepeticao === 1 && !valores[index].exercinformarepeticao) ||
                    (exercicio.exercinformaflexibilidade === 1 && !valores[index].exercinformaflexibilidade))
                    isValid = false;
            });

        if (isValid) {
            const hasProfessores = refProfessores.current.getValue();
            isValid = (hasProfessores) && (hasProfessores.length > 0);
        }

        return isValid;
    }

    useImperativeHandle(ref, () => ({
        openModal
    }));

    return (
        <VbModal
            ref={refModal}
            icon={<RiBodyScanFill style={{ fontSize: '24px' }} />}
            title="Confirmar Presença"
            success={() => apply()}
            size='lg'
        >
            <Row className="align-items-center mb-3" >
                <VbControls ref={refProfessores} id='usuid' name='usuid' type='select' label='Professor' md={'12'} required model={model_usuario} isMulti />
            </Row>
            {exercicios.map((exercicio, index) => (
                <Form key={index} className="mb-3" >
                    <Row className="align-items-center">
                        <Col xs={isMobileDevice() ? 12 : 3} className="text-left">
                            <div style={{ fontSize: '14px' }}>{exercicio.exercdescricao}</div>
                        </Col>
                        <InputMetrica
                            id={exercicio.exercid}
                            label="Peso"
                            onChange={(value) => handleChange(index, 'exercinformapeso', value)}
                            disabled={exercicio.exercinformapeso !== 1}
                        />
                        <InputMetrica
                            id={exercicio.exercid}
                            label="Repetição"
                            onChange={(value) => handleChange(index, 'exercinformarepeticao', value)}
                            disabled={exercicio.exercinformarepeticao !== 1}
                        />
                        <InputMetrica
                            id={exercicio.exercid}
                            label="Flexibilidade"
                            onChange={(value) => handleChange(index, 'exercinformaflexibilidade', value)}
                            disabled={exercicio.exercinformaflexibilidade !== 1}
                        />
                    </Row>
                </Form>
            ))}
        </VbModal >
    );
});
