import { forwardRef, useImperativeHandle, useRef, useState } from "react";
import { VbModal } from "../../../components/VbModal";
import { useAuth } from "../../../contexts/AuthProvider/useAuth";
import styled from "styled-components";
import { VbButton } from "../../../components/VbButton";
import { COLOR_PURPLE, COLOR_RED } from "../../../theme/styles";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { VbSwitch } from "../../../components/VbSwitch";
import { formatData, toastError } from "../../../commons/utils";
import { toast } from "react-toastify";
import { MdOutlineHistory } from "react-icons/md";
import { FaRegPaste } from "react-icons/fa6";
import { HistoricoAgendamentoProspect } from "../../Commons/HistoricoAgendamentoProspect";
import { CardPlanoAula } from "../../Commons/CardPlanoAula";
import { InformarMetricas } from "../InformarMetricas";

const Container = styled.div`
    max-height: 70vh;
    overflow-x: hidden;
    overflow-y: visible;
    padding: 0;
`

const Row = styled.div`
    padding: 3px 3px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
`

const Col = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60px;
    margin: 0px 10px;
`

const RoundedButton = styled(VbButton)`
    border-radius: 50%;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    font-size: 26px;
`;

export const ListaProspects = forwardRef((props: any, ref) => {
    const auth = useAuth();

    const refModal = useRef<any>();
    const refHistorico = useRef<any>();
    const refCard = useRef<any>();
    const refMetricas = useRef<any>();

    const [data, setData] = useState<any[]>();
    const [dataAula, setDataAula] = useState<any>();

    async function refresh(_data: any) {
        auth.get('/agendas?turmaid=' + _data.turmaid + '&agendadia=' + _data.agendadia + '&aulainicio=' + _data.aulainicio)
            .then((response: any) => setData(response.data.dados))
            .catch((error: any) => toastError(error));
    }

    async function deleteAgenda(agendaid: any) {
        await auth.deleta('/agenda', { agendaid })
            .then(() => {
                const newData = data?.filter((agenda: any) => agenda.agendaid !== agendaid);
                setData(newData);
                toast.success('Agenda excluída com sucesso.');
            })
            .catch((error: any) => toastError(error))
    }

    async function applyPresenca(response: any) {
        setData((prevData: any) => {
            return prevData.map((item: any) => {
                if (item.agendaid === response.data.dados.agendaid) {
                    return {
                        ...item,
                        agendapresente: response.data.dados.agendapresente
                    };
                }

                return item;
            });
        });
    }

    function openModal(_data: any) {
        setData([]);
        refModal.current.setLoading(false);
        refModal.current.openModal();

        setDataAula(_data);
        refresh(_data);
    }

    useImperativeHandle(ref, () => ({
        openModal
    }));

    async function getPlanoAula() {
        refCard.current.openModal(undefined, dataAula.modalidid, dataAula.agendadia)
    }

    function customheader() {
        return (
            <VbButton onClick={() => getPlanoAula()} icon={<FaRegPaste style={{ fontSize: '18px' }} />}>Plano de Aula</VbButton>
        )
    }

    async function informaPresenca(agenda: any) {
        await auth.get('/planoAula?modalidid=' + dataAula.modalidid + '&planoauladata=' + dataAula.agendadia)
            .then((response: any) => {
                const dados = response.data.dados;

                const exercicios = dados?.etapas?.flatMap((etapa: any) =>
                    etapa.etapa.exercicios.map((exercicio: any) => exercicio.exercicio)
                );

                refMetricas.current.openModal(
                    {
                        empid: agenda.prospect.empresa.empid,
                        prospid: agenda.prospect.prospid,
                        prospmetid: 0,
                        modalidid: dataAula.modalidid,
                        prospmetdata: agenda.dataagenda,
                        agendaid: agenda.agendaid
                    },
                    exercicios);
            });
    }

    return (
        <>
            <VbModal
                ref={refModal}
                title={dataAula?.turmadescricao + ' - ' + formatData(dataAula?.agendadia) + ' ' + dataAula?.aulainicio}
                size="lg"
                customheader={customheader}
            >
                <Container>
                    <Row style={{ fontWeight: 500 }}>
                        <div>Prospect</div>
                        <Row>
                            <Col>Presente</Col>
                            <Col>Histórico</Col>
                            <Col>Excluir</Col>
                        </Row>
                    </Row>
                    {
                        data?.map((agenda: any, i: any) => {
                            return (
                                <Row>
                                    <div>{agenda?.prospect?.prospnome}</div>
                                    <Row>
                                        <Col>
                                            <VbSwitch
                                                disabled={agenda.agendapresente === 1}
                                                checked={agenda.agendapresente === 1}
                                                onChange={() => informaPresenca(agenda)}
                                            />
                                        </Col>
                                        <Col>
                                            <RoundedButton
                                                size='sm'
                                                color={COLOR_PURPLE}
                                                onClick={() => refHistorico.current.openModal({
                                                    empid: agenda.prospect.empresa.empid,
                                                    prospid: agenda.prospect.prospid,
                                                    prospnome: agenda.prospect.prospnome
                                                })}
                                                iconcenter={<MdOutlineHistory />}
                                            />
                                        </Col>
                                        <Col>
                                            <RoundedButton
                                                size='sm'
                                                color={COLOR_RED}
                                                onClick={() => deleteAgenda(agenda.agendaid)}
                                                iconcenter={<DeleteOutlineIcon />}
                                            />
                                        </Col>
                                    </Row>
                                </Row>
                            )
                        })
                    }
                </Container>
            </VbModal >
            <HistoricoAgendamentoProspect ref={refHistorico} />
            <CardPlanoAula ref={refCard} />
            <InformarMetricas ref={refMetricas} success={(response: any) => applyPresenca(response)} />
        </>
    );
});