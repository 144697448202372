import { forwardRef, useImperativeHandle, useRef, useState } from "react";
import { Grid } from "@mui/material";
import styled from "styled-components";
import { useAuth } from "../../../../../contexts/AuthProvider/useAuth";
import { toastError } from "../../../../../commons/utils";
import { VbModal } from "../../../../../components/VbModal";
import SettingsIcon from "@mui/icons-material/Settings";
import { VbCheckBox } from "../../../../../components/VbCheckBox";
import { LuCopyCheck } from "react-icons/lu";
import { LuCopyMinus } from "react-icons/lu";
import { VbButton } from "../../../../../components/VbButton";
import Pagination from "react-bootstrap/Pagination";

const HeaderCell = styled.div`
  font-weight: bold;
  font-size: 14px;
  text-align: center;
`;

const DataCell = styled.div`
  font-size: 14px;
  text-align: center;
`;

const PrivilegioCheckbox = ({
    value,
    onClick,
}: {
    value: boolean;
    onClick: (newValue: boolean) => void;
}) => (
    <Grid item xs={1.75} style={{ display: "flex", justifyContent: "center" }}>
        <div>
            <VbCheckBox
                checked={value}
                onClick={(e: any) => onClick(e.target.checked)}
            />
        </div>
    </Grid>
);

const PrivilegioItem = ({
    priv,
    onUpdate,
}: {
    priv: any;
    onUpdate: (updatedPriv: any, action?: string) => void;
}) => (
    <Grid container spacing={2} sx={{ marginBottom: 1 }}>
        <Grid item xs={3}>
            <DataCell style={{ textAlign: "left" }}>{priv.privitemdescricao}</DataCell>
        </Grid>
        <PrivilegioCheckbox
            value={priv.privitemacessar}
            onClick={(newValue) =>
                onUpdate({ ...priv, privitemacessar: newValue })
            }
        />
        <PrivilegioCheckbox
            value={priv.privitemincluir}
            onClick={(newValue) =>
                onUpdate({ ...priv, privitemincluir: newValue })
            }
        />
        <PrivilegioCheckbox
            value={priv.privitemeditar}
            onClick={(newValue) =>
                onUpdate({ ...priv, privitemeditar: newValue })
            }
        />
        <PrivilegioCheckbox
            value={priv.privitemexcluir}
            onClick={(newValue) =>
                onUpdate({ ...priv, privitemexcluir: newValue })
            }
        />

        <Grid item xs={1} style={{ display: "flex", justifyContent: "center" }}>
            <VbButton
                size="sm"
                color={"rgba(248, 109, 29, 0.65)"}
                className="m-0 me-2 p-1"
                onClick={() => onUpdate(priv, "check")}
                iconcenter={<LuCopyCheck style={{ fontSize: "20px" }} />}
            />
        </Grid>
        <Grid item xs={1} style={{ display: "flex", justifyContent: "center" }}>
            <VbButton
                size="sm"
                color={"rgba(248, 109, 29, 0.65)"}
                className="m-0 me-2 p-1"
                onClick={() => onUpdate(priv, "uncheck")}
                iconcenter={<LuCopyMinus style={{ fontSize: "20px" }} />}
            />
        </Grid>
    </Grid>
);

export const ConfiguracaoPrivilegio = forwardRef((props: any, ref) => {
    const auth = useAuth();
    const refModal = useRef<any>();

    const [privilegios, setPrivilegios] = useState<any[]>([]);
    const [currentPage, setCurrentPage] = useState(1);

    const ITEMS_PER_PAGE = 10;
    const totalPages = Math.ceil(privilegios.length / ITEMS_PER_PAGE);
    const paginatedPrivilegios = privilegios.slice(
        (currentPage - 1) * ITEMS_PER_PAGE,
        currentPage * ITEMS_PER_PAGE
    );

    async function updatePrivilegio(priv: any) {
        await auth
            .post("/privilegioItem", priv)
            .then((response: any) => {
                console.log(response);
            })
            .catch((error: any) => {
                toastError(error);
            });
    }

    const handleUpdate = (updatedPriv: any, action?: string) => {
        if (action) {
            const value = action === "check";
            updatedPriv.privitemacessar = value;
            updatedPriv.privitemincluir = value;
            updatedPriv.privitemeditar = value;
            updatedPriv.privitemexcluir = value;
        }

        setPrivilegios((prevState) => {
            const updatedPrivilegios = prevState.map((priv) =>
                priv.privid === updatedPriv.privid &&
                    priv.privitemid === updatedPriv.privitemid
                    ? updatedPriv
                    : priv
            );

            return updatedPrivilegios;
        });

        updatePrivilegio(updatedPriv);
    };

    async function openModal(privid: any) {
        setPrivilegios([]);
        setCurrentPage(1);

        refModal.current.openModal(true);

        await auth
            .get(`/privilegio/detalhes?privid=${privid}`)
            .then((response: any) => {
                setPrivilegios(response.data.dados);
            })
            .catch((error: any) => {
                toastError(error);
            });
    }

    useImperativeHandle(ref, () => ({
        openModal,
    }));

    const handlePageChange = (page: number) => {
        setCurrentPage(page);
    };

    return (
        <VbModal
            ref={refModal}
            title="Configuração de Privilégios"
            icon={<SettingsIcon />}
            size="lg"
        >
            <Grid container spacing={2} sx={{ marginBottom: 2 }}>
                <Grid item xs={3}>
                    <HeaderCell style={{ textAlign: "left" }}>Tela</HeaderCell>
                </Grid>
                <Grid item xs={1.75}>
                    <HeaderCell>Acessar</HeaderCell>
                </Grid>
                <Grid item xs={1.75}>
                    <HeaderCell>Incluir</HeaderCell>
                </Grid>
                <Grid item xs={1.75}>
                    <HeaderCell>Editar</HeaderCell>
                </Grid>
                <Grid item xs={1.75}>
                    <HeaderCell>Excluir</HeaderCell>
                </Grid>
            </Grid>

            {paginatedPrivilegios.map((priv: any, index: number) => (
                <PrivilegioItem key={index} priv={priv} onUpdate={handleUpdate} />
            ))}

            <div style={{ display: "flex", justifyContent: "center", marginTop: "16px" }}>
                <Pagination>
                    {[...Array(totalPages)].map((_, index) => (
                        <Pagination.Item
                            key={index}
                            active={index + 1 === currentPage}
                            onClick={() => handlePageChange(index + 1)}
                            className="text-white"
                        >
                            {index + 1}
                        </Pagination.Item>
                    ))}
                </Pagination>
            </div>
        </VbModal>
    );
});
