import { getLabelSimNao } from "../../../../commons/utils";
import { ConsultaPadrao } from "../../../../components/ConsultaPadrao";
import { model_exercicio } from "../../../../models";

export const Exercicio = () => {
    const columns = [
        { field: 'exercid', header: 'Código' },
        { field: 'exercdescricao', header: 'Descrição' },
        { field: 'exercinformapeso', header: 'Informa Peso', body: (e: any) => getLabelSimNao(e.exercinformapeso), style: { width: '150px' } },
        { field: 'exercinformarepeticao', header: 'Informa Repetição', body: (e: any) => getLabelSimNao(e.exercinformarepeticao), style: { width: '150px' } },
        { field: 'exercinformaflexibilidade', header: 'Informa Flexibilidade', body: (e: any) => getLabelSimNao(e.exercinformaflexibilidade), style: { width: '150px' } },
        { field: 'exercativo', header: 'Ativo' },
        { field: 'functions', style: { minWidth: '7rem', width: '7rem' } }
    ];

    return (
        <ConsultaPadrao model={model_exercicio} columns={columns} />
    )
}