import { Grid } from "@mui/material";
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import { VbSelect } from "../../../components/VbSelect";
import { Input } from "../../../components/Input";
import { model_controle_prospeccao, model_empresa, model_modalidade, model_usuario } from "../../../models";
import moment from "moment";
import { useAuth } from "../../../contexts/AuthProvider/useAuth";
import { formatDataCompare, getLocalStorage, isMobileDevice, setLocalStorage, toastError } from "../../../commons/utils";

export const FiltroProspect = forwardRef((props: any, ref: any) => {
    const auth = useAuth();

    const [data, setData] = useState<any>();

    const refUnidade = useRef<any>();
    const refUsuario = useRef<any>();
    const refCadastroInicial = useRef<any>();
    const refCadastroFinal = useRef<any>();
    const refModalidade = useRef<any>();

    function refresh() {
        change(true);
    }

    useImperativeHandle(ref, () => ({
        refresh
    }))

    async function getDados(refresh: boolean, consultores?: any, cadastro_inicial?: any, cadastro_final?: any, modalidades?: any, unidades?: any) {
        let _data: any;

        if (!refresh && data)
            _data = data;
        else {
            await auth.get(model_controle_prospeccao.api_get)
                .then((response: any) => {
                    const sortedData = response.data.dados.sort((a: any, b: any) => b.prospid - a.prospid);
                    setData(sortedData);
                    _data = sortedData;
                })
                .catch((error: any) => {
                    toastError(error)
                })
        }

        let _consultores = consultores;
        let _cadastro_inicial = cadastro_inicial;
        let _cadastro_final = cadastro_final;
        let _modalidades = modalidades;
        let _unidades = unidades;

        if (!_consultores)
            _consultores = refUsuario.current.getValue();

        if (!_modalidades)
            _modalidades = refModalidade.current.getValue();

        if (!_cadastro_inicial)
            _cadastro_inicial = refCadastroInicial.current.getValue();

        if (!_cadastro_final)
            _cadastro_final = refCadastroFinal.current.getValue();

        if (!_unidades)
            _unidades = refUnidade.current.getValue();

        if (_consultores && (_consultores.length > 0)) {
            const usuidValues = _consultores.map((consultor: any) => consultor.value);
            _data = _data.filter((item: any) => usuidValues.includes(item.usuario.usuid));
        }

        if (_modalidades && (_modalidades.length > 0)) {
            const modalidValues = _modalidades.map((modalidade: any) => modalidade.value);
            _data = _data.filter((item: any) => modalidValues.includes(item.modalidade.modalidid));
        }

        if (_unidades && (_unidades.length > 0)) {
            const unididVavlues = _unidades.map((unidade: any) => unidade.value);
            _data = _data.filter((item: any) => unididVavlues.includes(item.empresa.empid));
        }

        if (_cadastro_inicial)
            _data = _data.filter((item: any) => formatDataCompare(item.controleProspeccao.contprospcadastro) >= _cadastro_inicial);

        if (_cadastro_final)
            _data = _data.filter((item: any) => formatDataCompare(item.controleProspeccao.contprospcadastro) <= _cadastro_final);

        // Armazena os filtros 
        setLocalStorage('consultores', _consultores ?? null);
        setLocalStorage('modalidades', _modalidades ?? null);
        setLocalStorage('cadastro_inicial', _cadastro_inicial ?? null);
        setLocalStorage('cadastro_final', _cadastro_final ?? null);
        setLocalStorage('unidades', _unidades ?? null);

        return _data;
    }

    function change(refresh: boolean, consultores?: any, iCadastro?: any, fCadastro?: any, modalidades?: any, unidades?: any) {
        getDados(refresh, consultores, iCadastro, fCadastro, modalidades, unidades).then((data: any) => {
            if (props.onGetData)
                props.onGetData(data);
        })
    }

    function changeConsultor(_newValue: any) { change(false, _newValue) }
    function changeCadastroInicial(_newValue: any) { change(false, null, _newValue) }
    function changeCadastroFinal(_newValue: any) { change(false, null, null, _newValue) }
    function changeModalidade(_newValue: any) { change(false, null, null, null, _newValue) }
    function changeUnidade(_newValue: any) { change(false, null, null, null, null, _newValue) }

    useEffect(() => {
        change(true);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Grid container spacing={2} style={{ ...props.style }}>
            <Grid item xs={isMobileDevice() ? 6 : 2}>
                <VbSelect ref={refUnidade} id='filterUnidade' label='Unidade' model={model_empresa} onAfterChange={changeUnidade} isMulti={true} defaultValue={getLocalStorage('unidades')} />
            </Grid>
            <Grid item xs={isMobileDevice() ? 6 : 3}>
                <VbSelect ref={refUsuario} id='filterUsuario' label='Consultor' model={model_usuario} onAfterChange={changeConsultor} isMulti={true} defaultValue={getLocalStorage('consultores')} />
            </Grid>
            <Grid item xs={isMobileDevice() ? 12 : 3}>
                <VbSelect ref={refModalidade} id='filterModalidade' label='Modalidade' model={model_modalidade} onAfterChange={changeModalidade} isMulti={true} defaultValue={getLocalStorage('modalidades')} />
            </Grid>
            <Grid item xs={isMobileDevice() ? 6 : 2}>
                <Input ref={refCadastroInicial} id="filterCadInicial" type="date" label="Cadastro Inicial" defaultValue={getLocalStorage('cadastro_inicial') ? getLocalStorage('cadastro_inicial') : moment().format('YYYY-MM-01')} onAfterChange={changeCadastroInicial} />
            </Grid>
            <Grid item xs={isMobileDevice() ? 6 : 2}>
                <Input ref={refCadastroFinal} id="filterCadInicial" type="date" label="Cadastro Final" defaultValue={moment().format('YYYY-MM-DD')} onAfterChange={changeCadastroFinal} />
            </Grid>
        </Grid>
    )
})