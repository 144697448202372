import React, { useContext, useImperativeHandle, useRef, useState } from "react";
import { Grid } from "@mui/material";
import { AuthContext } from "../../../../contexts/AuthProvider";
import { toast } from 'react-toastify';
import { VbModal } from "../../../../components/VbModal";
import { Input } from "../../../../components/Input";
import { Form } from "react-bootstrap";
import { VbButton } from "../../../../components/VbButton";

export const MensagemTeste = React.forwardRef((props: any, ref) => {
    const auth = useContext(AuthContext);

    const dialogRef = useRef<any>();
    const zapref = useRef<any>();

    const [id, setId] = useState<any>();
    const [validated, setValidated] = useState(false);

    function openModal(_id: any) {
        setValidated(false);
        setId(_id);

        dialogRef.current.openModal();
    }

    useImperativeHandle(ref, () => ({
        openModal
    }));

    const sendMsgTeste = () => {
        async function sendMsg() {
            dialogRef.current.setLoading(true);

            const request: any = {
                numero: zapref.current.getValue(),
                envioImediato: 0,
                instid: id
            };

            try {
                const response: any = await auth.post('/instancia/mensagemTeste', request);

                toast.success(response.data.mensagem);

                dialogRef.current.closeModal();
            } catch (error: any) {
                toast.error(error?.response?.data?.mensagem ? error?.response?.data?.mensagem : 'Erro ao concluir a operação.')
            }

            dialogRef.current.setLoading(false);
        }

        setValidated(true);

        sendMsg();
    };

    const handleSubmit = (e: any) => {
        console.log('submit')
        const form = e.currentTarget;

        if (form.checkValidity() === true)
            sendMsgTeste()
        else
            setValidated(true);

        e.preventDefault();
        e.stopPropagation();
    };
    return (

        <VbModal ref={dialogRef} title={'Enviar Mensagem de Teste'} footer={<VbButton type="submit" form="form-msg-test">Enviar</VbButton>}>
            <Form id="form-msg-test" noValidate validated={validated} className="w-100" onSubmit={handleSubmit}>
                <Grid container spacing={2}  >
                    <Grid item xs={12}>
                        <Input ref={zapref} id="zap" type="text" label="WhatsApp" required maxLength={20} />
                    </Grid>
                </Grid>
            </Form>
        </VbModal>

    )
})