import { forwardRef, useImperativeHandle, useState } from "react";
import { Modal } from "react-bootstrap";
import { VbButton } from "../VbButton";
import { Title, Icon } from "./components";

export const VbModal = forwardRef((props: any, ref) => {
    const [show, setShow] = useState(false);
    const [loading, setLoading] = useState(false);

    function openModal() {
        setShow(true);
    }

    function closeModal() {
        setShow(false);
    }

    useImperativeHandle(ref, () => ({
        openModal,
        closeModal,
        setLoading
    }));

    return (
        <Modal
            show={props.show || show}
            onHide={() => props.onHide ? props.onHide() : closeModal()}
            backdrop='static'
            keyboard={false}
            centered={props.top ? false : true}
            size={props.size ? props.size : null}
        >
            <Modal.Header className="p-3" style={{ display: '' }}>
                <Title>
                    <Icon>{props?.icon}</Icon>
                    {props.title}
                </Title>
                {props.customheader && props.customheader()}
            </Modal.Header>
            <Modal.Body>
                {props.children}
            </Modal.Body>
            <fieldset disabled={loading} >
                <Modal.Footer>
                    <VbButton onClick={() => props.cancel ? props.cancel() : closeModal()}>Sair</VbButton>
                    {props.success && <VbButton onClick={() => props.success && props.success()}>Confirmar</VbButton>}
                    {props.footer}
                </Modal.Footer>
            </fieldset >
        </Modal>
    )
})