import { IconButton } from '@mui/material';
import { useRef, useState, useImperativeHandle, forwardRef } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import styled from 'styled-components';

const Container = styled.div`
     display: flex;
     align-items: center;
     justify-content: center;
     height: 'auto';
     padding: 10px; 
     border-radius: 3px;
     flex-direction: column;
     cursor: pointer;
     height: 45px;
`

const FileUploader = forwardRef((props: any, ref: any) => {
    const [selectedFile, setSelectedFile] = useState<any>(null);
    const [fileInfo, setFileInfo] = useState<any>(null);
    const [visible, setVisible] = useState<any>(true);

    const fileInputRef = useRef<any>(null);

    const readFile = (file: any) => {
        const reader: any = new FileReader();
        reader.onload = () => {
            const base64String = reader.result.split(',')[1];
            setFileInfo({
                name: file.name.replace(/\.[^/.]+$/, ''),
                type: file.type.split('/')[0],
                extension: file.name.split('.').pop(),
                base64: base64String,
            });
        };

        reader.readAsDataURL(file);
    };

    function getFileInfo() {
        return fileInfo;
    }

    function disableControl(value: any) {
        if (!value) {
            setFileInfo(null);
            setSelectedFile(null);
        }

        setVisible(value);
    }

    useImperativeHandle(ref, () => ({
        getFileInfo,
        disableControl
    }));

    const handleFileSelect = (event: any) => {
        const file = event.target.files && event.target.files[0];
        setSelectedFile(file || null);

        if (file)
            readFile(file);
    };

    const handleDivClick = (e: any) => {
        if (!e.target.closest('button'))
            fileInputRef.current.click();
    };

    return (
        visible &&
        <Container onClick={handleDivClick} style={{ border: '2px dashed #818181', ...props.style }}>
            {!selectedFile?.name ?
                <span>SELECIONE UM ARQUIVO</span> :
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <span style={{ marginRight: '10px' }}>
                        {selectedFile?.name} {'(' + Number(selectedFile?.size / (1024)).toFixed(0) + 'KB)'}
                    </span>
                    <IconButton sx={{ color: '#fff' }} onClick={() => { setSelectedFile(null); setFileInfo(null) }}>
                        <DeleteIcon />
                    </IconButton>
                </div>
            }

            <input
                type='file'
                ref={fileInputRef}
                accept={props.accept || '*/*'}
                onChange={handleFileSelect}
                style={{ display: 'none' }}
            />
        </Container >
    );
});

export default FileUploader;
