import { ConsultaPadrao } from "../../../../components/ConsultaPadrao";
import { getSexo } from "../../../../models";
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { COLOR_DARKGRAY, COLOR_GREEN, COLOR_PRIMARY } from "../../../../theme/styles";
import styled from "styled-components";
import { Col, Row } from "react-bootstrap";
import { ageCalculator, formatData, formatDataHora, sendMessageBitSafira } from "../../../../commons/utils";
import { VbButton } from "../../../../components/VbButton";
import { LuFileSymlink } from "react-icons/lu";
import { LuFileCheck } from "react-icons/lu";
import { GenerateAnamnese } from "../Pessoa/Anamnese";
import { useAuth } from "../../../../contexts/AuthProvider/useAuth";
import { FaRegCopy } from "react-icons/fa";
import copy from "copy-to-clipboard";
import { toast } from "react-toastify";
import { getApi } from "../../../../api/address";
import { useRef } from "react";
import { PDFViewer } from "../../../../components/PDFViewer";

const ContainerDetail = styled.div`
    font-size: 12px;
    padding: 0 1rem;
`
const LabelDetail = styled.div`
    background-color: rgba(0, 0, 0, .3);
    border-radius: 4px;
    margin: 5px 1rem 8px 0px;
    padding: 4px 10px;
`
const RowDetail = styled(Row)`
    margin: 0;
    padding-left: 5px;
    margin-bottom: 3px;
`

function drawImage(foto: any) {
    return (
        foto ? <img src={foto} width={33} height={33} alt="Imagem Prospect" style={{ border: 'none', borderRadius: '50%' }} /> : <div></div>
    )
}

export const columns_pessoa = [
    { field: 'prospfoto', header: '', body: (e: any) => drawImage(e.prospfoto), notSortable: true, style: { width: '33px' } },
    { field: 'prospid', header: 'Código' },
    { field: 'empresa.empnome', header: 'Unidade' },
    { field: 'prospnome', header: 'Nome' },
    { field: 'usuario.usunome', header: 'Consultor' },
    { field: 'prosptelefone', header: 'Telefone' },
    { field: 'functions', style: { minWidth: '4rem', width: '4rem' } }
];

export const details_pessoa = (row: any) => {
    return (
        <ContainerDetail>
            <Row>
                <Col className={"p-0"}>
                    <LabelDetail>ENDEREÇO</LabelDetail>
                    <RowDetail>Endereço: {row.prosplogradouro} Nº {row.prospnumero}</RowDetail>
                    <RowDetail>Bairro: {row.prospbairro}</RowDetail>
                    <RowDetail>Cidade: {row.prospcidade}/{row.prospuf}</RowDetail>
                    <RowDetail>Complemento: {row.prospcomplemento} </RowDetail>
                    <RowDetail>CEP: {row.prospcep}</RowDetail>
                </Col>
                <Col className={"p-0"}>
                    <LabelDetail>DADOS PESSOAIS</LabelDetail>
                    <RowDetail>CPF: {row.prospcpf}</RowDetail>
                    <RowDetail>Nascimento: {formatData(row.prospnascimento)} ({ageCalculator(row.prospnascimento)} anos)</RowDetail>
                    <RowDetail>E-mail: {row.prospemail}</RowDetail>
                    <RowDetail>
                        <div style={{ display: 'flex', alignItems: 'center', padding: 0 }}>
                            <div style={{ marginRight: '5px' }}>Telefone Emergência: {row.prosptelemergencia}</div>
                            <VbButton
                                size='sm'
                                icon={<WhatsAppIcon style={{ fontSize: '20px', marginTop: '-1px' }} />}
                                color={COLOR_GREEN}
                                style={{ padding: '2px', height: '25px', width: '25px', borderRadius: '50%' }}
                                onClick={() => window.open('https://wa.me/+55' + row.prosptelemergencia.replace(/\D/g, ""), '_blank')}
                            />
                        </div>
                    </RowDetail>
                    <RowDetail>Sexo: {getSexo(row.prospsexo)}</RowDetail>
                </Col>
                <Col className={"p-0"}>
                    <LabelDetail>CADASTRO</LabelDetail>
                    <RowDetail>Data: {formatDataHora(row.prospdatacadastro)}</RowDetail>
                    <RowDetail>Tipo Prospecção: {row.tipoProspeccao.tipprospecdescricao}</RowDetail>
                </Col>
            </Row>
        </ContainerDetail >
    )
}

interface PessoaProps {
    model: any;
    table_functions?: any;
}

export const Pessoa: React.FC<PessoaProps> = ({ model, table_functions }) => {
    const auth = useAuth();
    const refAnamnese = useRef<any>();

    const handleAnamneseClick = async (row: any) => {
        if (row.anamnese.anamnid > 0) {
            const anamnese = await GenerateAnamnese(row, row.anamnese);
            refAnamnese.current.openModal(anamnese);
        } else {
            sendMessageBitSafira(row.prosptelefone, row.prospnome, row.prosptoken, auth);
        }
    };

    function functions(row: any) {
        return ([
            ...(table_functions && typeof table_functions === 'function' ? table_functions(row) : []),
            {
                label: 'Abrir WhatsApp Web',
                click: () => window.open(`https://wa.me/+55${row.prosptelefone.replace(/\D/g, '')}`, '_blank'),
                icon: <WhatsAppIcon />,
                color: COLOR_GREEN
            },
            {
                label: row.anamnese.anamnid > 0 ? 'Baixar Anamnese Respondida' : 'Enviar Anamnese',
                click: handleAnamneseClick,
                icon: row.anamnese.anamnid > 0 ?
                    <LuFileCheck style={{ fontSize: '24px', padding: '2px' }} /> :
                    <LuFileSymlink style={{ fontSize: '24px', padding: '2px' }} />,
                color: row.anamnese.anamnid > 0 ? COLOR_PRIMARY : COLOR_DARKGRAY
            }
        ])
    }

    function headerfunctions() {
        return ([
            {
                title: 'Copiar URL',
                click: () => {
                    copy('https://crm' + getApi() + '.vbfit.com.br/prospect-form?u_vbfit=' + auth.usuarioEmpresa.usuemptoken);
                    toast.success('URL copiada com sucesso.')
                },
                icon: <FaRegCopy />
            }
        ])
    }

    return (
        <>
            <ConsultaPadrao
                model={model}
                columns={columns_pessoa}
                functions={functions}
                details={details_pessoa}
                headerfunctions={headerfunctions}
            />
            <PDFViewer ref={refAnamnese} />
        </>
    )
}