import { forwardRef, useRef, useState } from "react";
import { Form, Row } from "react-bootstrap";
import LogoFull from "../../../Images/logo_full.png"
import LogoEtapa from "../../../Images/cadastrando_prospect.png"
import { model_prospect } from "../../../models";
import { VbControls } from "../../../components/VbControls";
import { VbButton } from "../../../components/VbButton";
import { getValues, onValidate, toastError } from "../../../commons/utils";
import { useAuth } from "../../../contexts/AuthProvider/useAuth";
import { toast } from "react-toastify";
import { Description, FormContainer, ImgEtapa, ImgLogo, Label, Title, VbCol } from "../commons";

export const ProspectPub = forwardRef((props: any, ref) => {
    const auth = useAuth();

    const params: any = new URLSearchParams(window.location.search);

    const rows: JSX.Element[] = [];
    let currentRow: JSX.Element[] = [];
    let currentMdSum = 0;

    const inputsEls = useRef<{ [key: string]: any }>({});

    const [validated, setValidated] = useState(false);
    const [loading, setLoading] = useState<any>(false);

    function getValueParams(field: any) {
        if ((field === 'prospnome') && params.get('nome'))
            return params.get('nome')
        else if ((field === 'prosptelefone') && params.get('telefone'))
            return params.get('telefone')
        else if ((field === 'tipoProspeccao.tipprospecid') && params.get('tipoprospeccao'))
            return params.get('tipoprospeccao')
    }

    model_prospect.inputs_pub.forEach((input: any, i: any) => {
        if (currentMdSum + input.md > 12) {
            rows.push(<Row key={i}>{currentRow}</Row>);
            currentRow = [];
            currentMdSum = 0;
        }

        currentRow.push(
            <Form.Group as={VbCol} md={input.md} key={i}>
                <VbControls
                    ref={(e: any) => inputsEls.current[input.id] = e}
                    id={input.id}
                    name={input.id}
                    type={input.type}
                    label={input.label}
                    disabled={input.disabled || getValueParams(input.id)}
                    required={input.required}
                    maxLength={input.maxLength}
                    options={input.options}
                    model={input.model}
                    validated={validated}
                    size={'default'}
                    onAfterChange={(_newValue: any) => { input.onAfterChange && input.onAfterChange(_newValue, inputsEls) }}
                    defaultValue={getValueParams(input.id)}
                />
            </Form.Group>
        );

        currentMdSum += input.md;
    });

    if (currentRow.length > 0) {
        rows.push(<Row className="mb-2" key={-1}>{currentRow}</Row>);
    }

    function upsert() {
        async function execute() {
            const values = getValues(model_prospect.base, inputsEls, model_prospect.key);

            if (params.get('empidlead'))
                values.empidlead = params.get('empidlead');

            if (params.get('leadid'))
                values.leadid = params.get('leadid');

            setLoading(true);

            await auth.post(model_prospect.api_post, values).then((response: any) => {
                toast.success('Cadastrado com sucesso.');

                window.location.replace('/anamnese-form?u_vbfit=' + params.get('u_vbfit') + '&u_prospect=' + response.data.dados.prosptoken);
            }).catch((error: any) => {
                toastError(error)
            }).finally(() => {
                setLoading(false);
            })
        }

        execute();
    }


    function cadastrarClick() {
        setValidated(true);

        if (onValidate(inputsEls))
            upsert();
    }

    return (
        <FormContainer className="d-flex flex-column">
            <Form noValidate validated={validated}>
                <Row className="mb-3 justify-content-center">
                    <ImgLogo src={LogoFull} />
                    <Row className="justify-content-center">
                        <ImgEtapa src={LogoEtapa} />
                    </Row>
                    <Row className="mb-3 justify-content-center">
                        <Label>CADASTRE-SE</Label>
                    </Row>
                    <Title>Aula Experimental</Title>
                    <Description>Tratamos a individualidade em uma aula de grupo, isso é VBFit..</Description>
                </Row>
                <fieldset disabled={loading}>
                    {rows}
                    <Row className="mt-3 mb-4 justify-content-center" style={{ padding: '0 8px' }}>
                        <VbButton size={undefined} style={{ justifyContent: 'center' }} onClick={() => cadastrarClick()}>
                            CADASTRAR
                        </VbButton>
                    </Row>
                </fieldset>
            </Form>
        </FormContainer >
    )
})