import { useRef } from "react";
import { model_prospect } from "../../../../models";
import { ViewMetricas } from "../../../Commons/ViewMetricas";
import { Pessoa } from "../Pessoa";
import QueryStatsIcon from '@mui/icons-material/QueryStats';

export const Cliente = () => {
    const refviewmetricas = useRef<any>();

    const model_cliente = {
        ...model_prospect,
        name: 'Cliente',
        api_get: '/controlesProspeccao?situacao=5',
        api_post: undefined,
        api_delete: '/prospect',
        base: undefined
    };

    function functions(row: any) {
        return ([
            {
                label: 'Visualizar Métricas',
                click: () => refviewmetricas.current.openModal(row),
                icon: <QueryStatsIcon />,
                color: '#511688'
            }
        ])
    }


    return (
        <>
            <Pessoa model={model_cliente} table_functions={functions} />
            <ViewMetricas ref={refviewmetricas} />
        </>
    )
}