import { Dialog } from '@mui/material';
import { useRef, useState, useImperativeHandle, forwardRef, useEffect } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import styled from 'styled-components';
import S3Service from '../../s3';

const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: auto;
    padding: 10px; 
    border-radius: 3px;
    flex-direction: column;
    cursor: pointer;
    border: 2px dashed #81818180;
`;

const PlaceholderText = styled.div`
    color: #fff;
    font-size: 14px;
`;

const StyledImage = styled.img`
    cursor: pointer;
    height: 300px;
    display: block;
`;

const DialogContent = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;

const ImageWrapper = styled.div`
    position: relative; 
`;

const CloseButton = styled.button`
    position: absolute;
    top: 5px;
    right: 5px; 
    background-color: rgba(0, 0, 0, .3); 
    border: none;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    cursor: pointer; 
    color: #fff; 
    display: flex;
    align-items: center;
    justify-content: center;
`;

const FotoUploader = forwardRef((props: any, ref: any) => {
    const [selectedFile, setSelectedFile] = useState<any>(null);
    const [fileInfo, setFileInfo] = useState<any>(null);
    const [openModal, setOpenModal] = useState(false);
    const [originUrl, setOriginUrl] = useState<any>();
    const [urlImage, setURLImage] = useState<any>();

    const fileInputRef = useRef<any>(null);
    const imgRef = useRef<any>(null);

    async function getValue() {
        if (urlImage)
            return urlImage;

        let url: any;
        let base64 = fileInfo?.base64 ? 'data:image/png;base64,' + fileInfo?.base64 : '';

        if (base64) {
            const s3Service = new S3Service();

            const [uploadSuccess, uploadedFileName] = await s3Service.uploadImage(base64, 'image/png');

            if (uploadSuccess) {
                const [urlSuccess, publicUrl] = await s3Service.getPublicUrl(uploadedFileName);

                if (urlSuccess)
                    url = publicUrl.split('?')[0]
                else
                    console.error(`Erro ao gerar URL pública: ${publicUrl}`);
            }
        }

        if ((originUrl) && ((!base64) || (originUrl !== url))) {
            const s3Service = new S3Service();

            const path: any = new URL(originUrl).pathname;
            const uuid: string = path.split('/').filter((segment: any) => segment.length > 0).pop();

            await s3Service.deleteFile(uuid);
        }

        return url;
    }

    function getId() {
        return props.id;
    }

    function validate(_value?: any) {
        return true;
    }

    useImperativeHandle(ref, () => ({
        getValue,
        getId,
        validate
    }));

    const readFile = (file: any) => {
        const reader: any = new FileReader();
        reader.onload = () => {
            const base64String = reader.result.split(',')[1];
            setFileInfo({
                name: file.name.replace(/\.[^/.]+$/, ''),
                type: file.type.split('/')[0],
                extension: file.name.split('.').pop(),
                base64: base64String,
            });
        };

        reader.readAsDataURL(file);
    };

    const handleFileSelect = (event: any) => {
        const file = event.target.files && event.target.files[0];
        setSelectedFile(file || null);

        if (file) {
            readFile(file);
        }
    };

    const handleDivClick = (e: any) => {
        if (!e.target.closest('button') && !e.target.closest('img')) {
            fileInputRef.current.click();
        }
    };

    useEffect(() => {
        if (props.defaultValue) {
            setURLImage(props.defaultValue);
            setOriginUrl(props.defaultValue);
        }
    }, [props.defaultValue]);

    return (
        <>
            <Container onClick={handleDivClick} style={props.style}>
                {(!urlImage && !selectedFile?.name) ? (
                    <PlaceholderText>SELECIONE UMA IMAGEM</PlaceholderText>
                ) : (
                    <ImageWrapper>
                        {(urlImage || fileInfo?.base64) && (
                            <>
                                <StyledImage
                                    src={urlImage ? urlImage : 'data:image/png;base64,' + fileInfo?.base64}
                                    ref={imgRef}
                                    onClick={() => setOpenModal(true)}
                                />
                                <CloseButton
                                    onClick={() => {
                                        setSelectedFile(null);
                                        setFileInfo(null);
                                        setURLImage(null);
                                    }}
                                >
                                    <CloseIcon />
                                </CloseButton>
                            </>
                        )}
                    </ImageWrapper>
                )}

                <input
                    type='file'
                    ref={fileInputRef}
                    accept='image/png, image/jpeg, image/jpg'
                    onChange={handleFileSelect}
                    style={{ display: 'none' }}
                />
            </Container >

            <Dialog
                open={openModal}
                onClose={() => setOpenModal(false)}
                maxWidth='lg'
                PaperProps={{ style: { backgroundColor: 'transparent', boxShadow: 'none' } }}
            >
                <DialogContent>
                    <img
                        src={urlImage ? urlImage : 'data:image/png;base64,' + fileInfo?.base64}
                        style={{ width: '100%', height: '100%', objectFit: 'contain' }}
                        alt={fileInfo?.name}
                    />
                </DialogContent>
            </Dialog>
        </>
    );
});

export default FotoUploader;
