import { Route, Routes } from "react-router-dom"
import { RequireAuth } from "../contexts/AuthProvider/RequireAuth"
import { Local } from "../pages/Cadastros/Outros/Local"
import { Modalidade } from "../pages/Cadastros/Outros/Modalidade"
import { TipoProspeccao } from "../pages/Cadastros/Outros/TipoProspeccao"
import { Turma } from "../pages/Cadastros/Outros/Turma"
import { Usuario } from "../pages/Cadastros/Pessoas/Usuario"
import { Privilegio } from "../pages/Cadastros/Outros/Privilegio"
import { UsuarioEmpresa } from "../pages/Cadastros/Pessoas/UsuarioEmpresa"
import { Prospect } from "../pages/Cadastros/Pessoas/Prospect"
import { ControleProspeccao } from "../pages/Kanbans/ControleProspeccao"
import { Logs } from "../pages/Consultas/Plataforma/Logs"
import { Aula } from "../pages/Cadastros/Outros/Aula"
import { Agenda } from "../pages/Agenda"
import { Feedback } from "../pages/Consultas/Plataforma/Feedback"
import { Doacao } from "../pages/Consultas/DoarSaude"
import { Dashboard } from "../pages/Dashboard"
import { Lead } from "../pages/Cadastros/Pessoas/Lead"
import { ControleLead } from "../pages/Kanbans/ControleLead"
import { Motivo } from "../pages/Cadastros/Outros/Motivo"
import { ReportFaltas } from "../pages/Relatorios/Faltas"
import { DashboardConvertidosProfessor } from "../pages/Dashboard/ConvertidosProfessor"
import { Metodo } from "../pages/Cadastros/Planejamento/Metodo"
import { Exercicio } from "../pages/Cadastros/Planejamento/Exercicio"
import { Especificacao } from "../pages/Cadastros/Planejamento/Especificacao"
import { Etapa } from "../pages/Cadastros/Planejamento/Etapa"
import { PlanoAula } from "../pages/Cadastros/Planejamento/PlanoAula"
import { PlanoAulaModalidade } from "../pages/Cadastros/Planejamento/PlanoAulaModalidade"
import { Cliente } from "../pages/Cadastros/Pessoas/Cliente"
import { TemplateMensagem } from "../pages/BitSafira/Template Mensagem"
import { Instancia } from "../pages/BitSafira/Instancia"

export const AppRoutes = () => {
    return (
        <Routes>
            <Route path="/" element={<RequireAuth><Dashboard /></RequireAuth>} />
            <Route path="/dashboard/convertidos_professor" element={<RequireAuth><DashboardConvertidosProfessor /></RequireAuth>} />
            <Route path="/local" element={<RequireAuth><Local /></RequireAuth>} />
            <Route path="/modalidade" element={<RequireAuth><Modalidade /></RequireAuth>} />
            <Route path="/tipoprospeccao" element={<RequireAuth><TipoProspeccao /></RequireAuth>} />
            <Route path="/turma" element={<RequireAuth><Turma /></RequireAuth>} />
            <Route path="/usuario" element={<RequireAuth><Usuario /></RequireAuth>} />
            <Route path="/privilegio" element={<RequireAuth><Privilegio /></RequireAuth>} />
            <Route path="/usuarioempresa" element={<RequireAuth><UsuarioEmpresa /></RequireAuth>} />
            <Route path="/prospect" element={<RequireAuth><Prospect /></RequireAuth>} />
            <Route path="/controleprospeccao" element={<RequireAuth><ControleProspeccao /></RequireAuth>} />
            <Route path="/aula" element={<RequireAuth><Aula /></RequireAuth>} />
            <Route path="/logs" element={<RequireAuth><Logs /></RequireAuth>} />
            <Route path="/agenda" element={<RequireAuth><Agenda /></RequireAuth>} />
            <Route path="/feedbacks" element={<RequireAuth><Feedback /></RequireAuth>} />
            <Route path="/doacoes" element={<RequireAuth><Doacao /></RequireAuth>} />
            <Route path="lead" element={<RequireAuth><Lead /></RequireAuth>} />
            <Route path="controlelead" element={<RequireAuth><ControleLead /></RequireAuth>} />
            <Route path="motivo" element={<RequireAuth><Motivo /></RequireAuth>} />
            <Route path="report_faltas" element={<RequireAuth><ReportFaltas /></RequireAuth>} />
            <Route path="metodo" element={<RequireAuth><Metodo /></RequireAuth>} />
            <Route path="exercicio" element={<RequireAuth><Exercicio /></RequireAuth>} />
            <Route path="especificacao" element={<RequireAuth><Especificacao /></RequireAuth>} />
            <Route path="etapa" element={<RequireAuth><Etapa /></RequireAuth>} />
            <Route path="planoaula" element={<RequireAuth><PlanoAula /></RequireAuth>} />
            <Route path="planoaulamodalidade" element={<RequireAuth><PlanoAulaModalidade /></RequireAuth>} />
            <Route path="cliente" element={<RequireAuth><Cliente /></RequireAuth>} />
            <Route path="templatemensagem" element={<RequireAuth><TemplateMensagem /></RequireAuth>} />
            <Route path="instancia" element={<RequireAuth><Instancia /></RequireAuth>} />
        </Routes>
    )
}