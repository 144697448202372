import styled from "styled-components";

const Container = styled.div`
  margin-top: 10px;
  padding: 10px;
  border-top: 1px solid rgba(255, 255, 255, .25);
  border-bottom: 1px solid rgba(255, 255, 255, .25);
`;

const Title = styled.div`
  font-weight: bold;
  margin-bottom: 5px;
`;

const Text = styled.p`
  font-size: 12px;
  margin: 5px 0;
`;

const tipo1 = "{prospectNome}, {modalidade}, {saudacao}, {linkAnamnese}";
const tipo2 = "{prospectNome}, {turma}, {modalidade}, {dataAula}, {saudacao}";
const tipo3 = "{prospectNome}, {modalidade}, {saudacao}";

export const InstrucoesTemplateMensagem = () => {
    return (
        <Container>
            <Title>INSTRUÇÕES:</Title>
            <Text>
                Customize como desejar as mensagens de envio. Quando utilizado alguma das
                variáveis disponíveis, a plataforma substituirá pelo valor referente à variável.
            </Text>
            <Title>EXEMPLO:</Title>
            <Text>
                Desejo colocar na mensagem o nome do meu cliente. Dessa forma, preciso apenas
                adicionar {'{nome}'} onde eu desejar que apareça.
            </Text>
            <Text>
                - Mensagem Padrão: Olá {'{nome}'}! <br />
                - Como ficará: Olá Bitsafira!
            </Text>
            <Title>VARIÁVEIS DISPONÍVEIS:</Title>
            <Text>
                <strong>SOLICITAR ANAMNESE:</strong> {tipo1}
            </Text>
            <Text>
                <strong>AGENDAMENTO AULA, CONFIRMAÇÃO PRESENÇA, PÓS EXPERIMENTAL, PÓS FALTA:</strong> {tipo2}
            </Text>
            <Text>
                <strong>AGUARDANDO RETORNO, CONVERTIDO, RESGATE:</strong> {tipo3}
            </Text>
        </Container>
    );
};
