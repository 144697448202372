import 'primeicons/primeicons.css';
import 'primereact/resources/themes/mdc-dark-deeppurple/theme.css'
import 'primereact/resources/primereact.css';
import './styles.css';
import { MdOutlineRefresh } from "react-icons/md";
import React, { useState, useMemo, forwardRef, memo, useDeferredValue, useImperativeHandle } from 'react';
import { DataTable as DataTablePrimeReact, DataTableProps } from 'primereact/datatable';
import { Input } from '../Input';
import styled from 'styled-components';
import { Col, Container } from 'react-bootstrap';
import { ButtonFooter } from '../VbButtonDataTable';
import { Tooltip } from '@mui/material';
import { VbButton } from '../VbButton';
import { COLOR_PRIMARY } from '../../theme/styles';
import { locale } from 'moment';

const MAX_ROWS = 50;

export const LabelDataTable = (props: any) => {
    return (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: props.color, borderRadius: '4px', padding: '2px', fontSize: '12px', fontWeight: 500 }}>
            {props.children}
        </div>
    )
}

const Header = styled(Container)`
    width: 100%;
    max-width: 100%;
    padding: 10px;
`

const TextHeader = styled.div`
    font-size: 14px;
    margin-right: 7px;
`

const ButtonHeader = styled(VbButton)`
    margin: 0px 3px;
    height: 25px;
`

interface VbDataTableProps extends DataTableProps {
    refresh?: any;
    columns?: any;
    model?: any;
    disabledsearch?: any;
}

const InternalDataTable = memo((props: VbDataTableProps & { setData: (data: any[]) => void, data: any[], searchText: string, ativo: boolean, inativo: boolean }) => {
    const { refresh, columns, model, disabledsearch, setData, data, searchText, ativo, inativo, ...dataTableProps } = props;

    const filteredData = useMemo(() => {
        let _data: any = [];

        if (!props.value)
            return _data;

        let _values: any = props.value;

        if (props.model?.inactive_field) {
            if (!ativo && !inativo)
                _values = [];
            else {
                _values = _values.filter((e: any) => {
                    const isActive = e[props.model.inactive_field] === 1;
                    return ativo && inativo ? true : ativo ? isActive : !isActive;
                });
            }
        }

        const searchTextProc = searchText.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '')
        const searchWords = searchTextProc.split(' ')

        _data = _values.filter((item: any) =>
            Object.values(item).some((field) => {
                const fieldProc = String(field).toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '')
                return searchWords.every(w => fieldProc.includes(w))
            })
        );

        return _data;

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.value, searchText, ativo, inativo]);

    return (
        <DataTablePrimeReact
            {...dataTableProps}
            paginatorRight={
                refresh ?
                    < Tooltip title="Recarregar" >
                        <ButtonFooter onClick={() => refresh()}>
                            <MdOutlineRefresh />
                        </ButtonFooter>
                    </Tooltip > :
                    <></>
            }
            paginatorLeft={<></>}
            rowHover
            size="small"
            responsiveLayout="stack"
            breakpoint="960px"
            resizableColumns
            emptyMessage="Nenhum registro encontrado."
            rows={MAX_ROWS}
            paginatorTemplate={
                Number(props.value?.length) > MAX_ROWS
                    ? "RowsPerPageDropdown PrevPageLink CurrentPageReport NextPageLink"
                    : "CurrentPageReport"
            }
            currentPageReportTemplate={
                Number(props.value?.length) > MAX_ROWS
                    ? "{first} à {last} de {totalRecords}"
                    : "{totalRecords} Registros"
            }
            expandedRowIcon="pi pi-angle-down"
            collapsedRowIcon="pi pi-angle-right"
            paginator={true}
            scrollHeight={props.scrollHeight ? props.scrollHeight : '100%'}
            sortField={props.sortField ? props.sortField : 'name'}
            sortOrder={props.sortOrder ? props.sortOrder : 1}
            value={filteredData}
        />
    );
});

export const DataTable = forwardRef((props: VbDataTableProps, ref) => {
    locale('pt');

    const [data, setData] = useState<any[]>([]);
    const [globalFilter, setGlobalFilter] = useState<any>('');
    const globalFilterDeferred = useDeferredValue(globalFilter)
    const [ativo, setAtivo] = useState<any>(true);
    const [inativo, setInativo] = useState<any>(false);

    const { model, disabledsearch } = props;

    function getData() {
        return data
    }

    useImperativeHandle(ref, () => ({
        getData,
        setData
    }));

    return (
        <>
            {!disabledsearch &&
                <Header>
                    <Col>
                        <Input id="search" type="text" placeholder="Pesquisar" size="md" search onAfterChange={(value: any) => setGlobalFilter(value)} noDebounce />
                    </Col>
                    {model?.inactive_field &&
                        <Col style={{ display: 'flex', alignItems: 'center', marginTop: '10px' }}>
                            <TextHeader>Mostrar:</TextHeader>
                            <ButtonHeader color={ativo ? COLOR_PRIMARY : '#747474'} onClick={() => setAtivo(!ativo)} >Ativos</ButtonHeader>
                            <ButtonHeader color={inativo ? COLOR_PRIMARY : '#747474'} onClick={() => setInativo(!inativo)} >Inativos</ButtonHeader>
                        </Col>
                    }
                </Header >
            }
            <InternalDataTable {...props} setData={setData} data={data} searchText={globalFilterDeferred} ativo={ativo} inativo={inativo} />
        </>
    );
});