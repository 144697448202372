import React, { forwardRef, useImperativeHandle, useRef, useState } from "react";
import { Grid } from "@mui/material";
import styled from "styled-components";
import { VbModal } from "../../../components/VbModal";
import { useAuth } from "../../../contexts/AuthProvider/useAuth";
import { toastError } from "../../../commons/utils";
import QueryStatsIcon from '@mui/icons-material/QueryStats';

const StyledMessage = styled.div`
    text-align: center;
    font-size: 16px;
    margin-top: 20px;
`;

const StyledGroup = styled.div`
    padding: 4px 16px;
`;

const StyledDate = styled.div`
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 12px;
`;

const StyledMetric = styled.div`
    margin-bottom: 8px;
    padding-bottom: 16px;
    margin-left: 15px;
    border-bottom: 1px solid #dddddd89;

    &:last-child {
        border-bottom: none;
    }
`;

const StyledTitle = styled.div`
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 8px;
`;

const StyledText = styled.div`
    font-size: 14px;
`;

export const ViewMetricas = forwardRef((props: any, ref) => {
    const auth = useAuth();
    const refModal = useRef<any>();

    const [metricsGroupedByDate, setMetricsGroupedByDate] = useState<any>({});

    async function openModal(_data: any) {
        refModal.current.openModal(true);

        try {
            const response: any = await auth.get(
                `/prospect/metricas?empidprosp=${_data.empresa.empid}&prospid=${_data.prospid}&modalidid=${_data.modalidade.modalidid}`
            );

            const groupedData = groupMetricsByDate(response.data.dados);
            setMetricsGroupedByDate(groupedData);
        } catch (error) {
            toastError(error);
        }
    }

    function groupMetricsByDate(data: any[]) {
        return data.reduce((acc: any, curr: any) => {
            const date = curr.prospmetdata;
            if (!acc[date]) {
                acc[date] = [];
            }
            acc[date].push(curr);
            return acc;
        }, {});
    }

    useImperativeHandle(ref, () => ({
        openModal,
    }));

    return (
        <VbModal ref={refModal} title="Métricas" icon={<QueryStatsIcon />} size="lg">
            {Object.keys(metricsGroupedByDate).length === 0 ? (
                <StyledMessage>Carregando métricas...</StyledMessage>
            ) : (
                <div>
                    {Object.entries(metricsGroupedByDate).map(([date, metrics]: any) => (
                        <StyledGroup key={date}>
                            <StyledDate>{new Date(date).toLocaleDateString()}</StyledDate>
                            {metrics.map((metric: any) => (
                                <StyledMetric key={metric.prospmetid}>
                                    <StyledTitle>{metric.exercicio.exercdescricao}</StyledTitle>
                                    <Grid container spacing={2}>
                                        <Grid item xs={4}>
                                            <StyledText>
                                                <strong>Flexibilidade:</strong>{" "}{metric.propmetflexibilidade ?? "N/A"}
                                            </StyledText>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <StyledText>
                                                <strong>Peso:</strong> {metric.prospmetpeso ?? "N/A"}
                                            </StyledText>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <StyledText>
                                                <strong>Repetições:</strong>{" "}{metric.prospmetrepeticao ?? "N/A"}
                                            </StyledText>
                                        </Grid>
                                    </Grid>
                                </StyledMetric>
                            ))}
                        </StyledGroup>
                    ))}
                </div>
            )}
        </VbModal>
    );
});
