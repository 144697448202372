import AWS from 'aws-sdk';
import { v4 as uuidv4 } from 'uuid';
import { Buffer } from 'buffer';

class S3Service {
    private s3: AWS.S3;
    private bucketName: string;

    constructor() {
        this.s3 = new AWS.S3({
            endpoint: 'https://s3.bitsafira.com.br',
            accessKeyId: 'Lk6Jnh8tNGAtooiFpYVB',
            secretAccessKey: 'KbWNnoypAxlyeuW7OlvLl99laI7lzIuybwkBPwGw',
            s3ForcePathStyle: true,
        });
        this.bucketName = 'vbfithom';
    }

    public async uploadImage(base64Image: string, contentType: string): Promise<[boolean, string]> {
        try {
            const uniqueFileName = uuidv4();
            const fileDataBuffer = Buffer.from(base64Image.split(',')[1], 'base64');

            const params = {
                Bucket: this.bucketName,
                Key: uniqueFileName,
                Body: fileDataBuffer,
                ContentType: contentType,
            };

            await this.s3.upload(params).promise();
            return [true, uniqueFileName];
        } catch (error: any) {
            return [false, `Error occurred during upload: ${error.message}`];
        }
    }

    public async getFile(fileName: string): Promise<[boolean, Buffer | string]> {
        try {
            const params = {
                Bucket: this.bucketName,
                Key: fileName,
            };

            const data = await this.s3.getObject(params).promise();
            return [true, data.Body as Buffer]; // Certifique-se de que `data.Body` é um Buffer
        } catch (error: any) {
            return [false, `Error occurred while retrieving the file: ${error.message}`];
        }
    }

    public async deleteFile(fileName: string): Promise<[boolean, string]> {
        try {
            const params = {
                Bucket: this.bucketName,
                Key: fileName,
            };

            await this.s3.deleteObject(params).promise();
            return [true, `File ${fileName} deleted successfully.`];
        } catch (error: any) {
            return [false, `Error occurred while deleting the file: ${error.message}`];
        }
    }

    public async getPublicUrl(fileName: string, expiresInSeconds: number = 172800): Promise<[boolean, string]> {
        try {
            const params = {
                Bucket: this.bucketName,
                Key: fileName,
                Expires: expiresInSeconds,
            };

            const url = this.s3.getSignedUrl('getObject', params);
            return [true, url];
        } catch (error: any) {
            return [false, `Error occurred while generating public URL: ${error.message}`];
        }
    }
}

export default S3Service;
