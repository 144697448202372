import { Typography } from "@mui/material";
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import { useAuth } from "../../../../contexts/AuthProvider/useAuth";
import { VbModal } from "../../../../components/VbModal";
import { CircularLoading } from "../../../../components/CircularLoading";

const TIME_SESSAO = 61;
const TIME_CONSULTA = 5;

export const QrCode = forwardRef((props: any, ref) => {
    const auth = useAuth();

    const dialogRef = useRef<any>();
    const [id, setId] = useState(0);

    const [qrCodeBase64, setQrCodeBase64] = useState<string>('');
    const [status, setStatus] = useState(-1);
    const [time, setTime] = useState(0);
    const [returnPending, setReturnPending] = useState(false);
    const [closed, setClosed] = useState(true);

    function openModal(id: any) {
        setClosed(false);
        setQrCodeBase64('');
        setId(id);
        setStatus(-1);
        setTime(0);

        refreshStatus(id);

        dialogRef.current.openModal();
    }

    useImperativeHandle(ref, () => ({
        openModal
    }));

    function refreshStatus(_id: any) {
        setReturnPending(true);

        auth.get('/instancia/info?instid=' + _id)
            .then((response: any) => {
                setStatus(response.data.dados.bitsafira.situacao);
                if (response.data.dados.bitsafira.situacao === 1) {
                    props.afterConnect();
                    dialogRef.current.closeModal();
                }
            })
            .catch((error: any) => {
                console.log(error)
            })
            .finally(() => {
                setReturnPending(false);
            });
    };

    useEffect(() => {
        async function iniciaSessao() {
            await auth.post('instancia/conectar', { instid: id }).then((response: any) => {
                setTime(TIME_SESSAO);
                setQrCodeBase64(response.data.dados.qrCode);
            });
        }

        if (!closed) {
            if (status === 0 && time === 0) {
                setTime(-1);
                iniciaSessao();
            } else if (time > 0 && status !== 1) {
                const interval = setInterval(() => {
                    setTime(prevTime => {
                        const newTime = prevTime - 1;
                        if (newTime % TIME_CONSULTA === 0) {
                            refreshStatus(id);
                        }
                        return newTime;
                    });
                }, 1000);

                return () => clearInterval(interval);
            } else if (id && status === -1) {
                refreshStatus(id);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [time, returnPending]);

    return (
        <VbModal ref={dialogRef} title={'Instruções para iniciar a sessão'} >
            <div>
                {!qrCodeBase64 ?
                    <div style={{ minHeight: '250px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <CircularLoading open={true} title={'Verificando Conexão...'} style />
                    </div> :
                    <>
                        <div>
                            <Typography variant="body2" gutterBottom> 1. Abra o WhatsApp no seu celular. </Typography>
                            <Typography variant="body2" gutterBottom> 2. Toque em <span style={{ fontWeight: 'bold' }}>Mais opções</span> ou <span style={{ fontWeight: 'bold' }}>Configurações</span> e selecione <span style={{ fontWeight: 'bold' }}>Aparelhos conectados.</span> </Typography>
                            <Typography variant="body2" gutterBottom> 3. Toque em <span style={{ fontWeight: 'bold' }}>Conectar um aparelho.</span> </Typography>
                            <Typography variant="body2" gutterBottom> 4. Aponte seu celular para esta tela para capturar o QrCode. </Typography>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'center', padding: '8px 0' }}>
                            < img style={{ height: '300px' }} alt='' src={qrCodeBase64} />
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            {time > 0 ?
                                <Typography variant="body2" gutterBottom>O <span style={{ fontWeight: 'bold' }}>QrCode</span> irá expirar em <span style={{ fontWeight: 'bold', color: time > 10 ? '#fff' : '#D32F2F' }}>{time}</span> segundo(s).</Typography> :
                                <Typography variant="body2" gutterBottom>Gerando novo QrCode...</Typography>
                            }
                        </div>
                    </>
                }
            </div >
        </VbModal>
    )
});