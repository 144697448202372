import axios from 'axios';
import * as CryptoJS from 'crypto-js';
import moment from 'moment';
import { differenceInYears } from 'date-fns';
import { toast } from 'react-toastify';
import { getApi } from '../api/address';
import { VbButton } from '../components/VbButton';
import 'moment/locale/pt-br';
import { VbTooltip } from '../components/VbTooltip';

export function convertToMd5(str: any) {
    return CryptoJS.MD5(str).toString();
}

export function navigate(to: any) {
    window.history.pushState({}, '', to);
    window.dispatchEvent(new PopStateEvent('popstate'));
}

export function isMobileDevice() {
    return window.matchMedia('(max-width: 767px)').matches;
};

export function onValidate(elements: any) {
    let validated = true;

    if (elements)
        Object.values(elements.current).forEach((input: any) => {
            if ((validated) && (!input.validate())) {
                validated = false
            }
        });

    return validated;
}

export function cpfIsValid(cpf: any) {
    var soma = 0;
    var resto;

    var cpfStr = cpf.replace(/[^\d]/g, '')

    if (cpfStr.length !== 11)
        return false;

    if ([
        '00000000000',
        '11111111111',
        '22222222222',
        '33333333333',
        '44444444444',
        '55555555555',
        '66666666666',
        '77777777777',
        '88888888888',
        '99999999999',
    ].indexOf(cpfStr) !== -1)
        return false;

    for (let i = 1; i <= 9; i++)
        soma = soma + parseInt(cpfStr.substring(i - 1, i)) * (11 - i);

    resto = (soma * 10) % 11

    if ((resto === 10) || (resto === 11))
        resto = 0

    if (resto !== parseInt(cpfStr.substring(9, 10)))
        return false

    soma = 0

    for (let i = 1; i <= 10; i++)
        soma = soma + parseInt(cpfStr.substring(i - 1, i)) * (12 - i)

    resto = (soma * 10) % 11

    if ((resto === 10) || (resto === 11))
        resto = 0

    if (resto !== parseInt(cpfStr.substring(10, 11)))
        return false

    return true
}

export function emailIsValid(email: any) {
    var user = email.substring(0, email.indexOf("@"));
    var dominio = email.substring(email.indexOf("@") + 1, email.length);

    if ((user.length >= 1) &&
        (dominio.length >= 3) &&
        (user.search("@") === -1) &&
        (dominio.search("@") === -1) &&
        (user.search(" ") === -1) &&
        (dominio.search(" ") === -1) &&
        (dominio.search(".") !== -1) &&
        (dominio.indexOf(".") >= 1) &&
        (dominio.lastIndexOf(".") < dominio.length - 1))
        return true
    else
        return false
}

export async function getApiCEP(cep: string): Promise<any> {
    try {
        const response = await axios.get(`https://viacep.com.br/ws/${cep}/json/`);
        return response.data;
    } catch (error) {
        console.error('Erro ao consultar o CEP:', error);
        throw error;
    }
}

export function getValues(_data: any, inputsEls: any, key?: any) {
    const result: any = { ..._data };

    if (inputsEls) {
        Object.values(inputsEls.current).forEach((input: any) => {
            const inputId = input.getId();

            if (inputId !== key) {
                const idParts = inputId.split('.');

                if (idParts?.length === 2) {
                    const [firstKey, secondKey] = idParts;
                    result[firstKey][secondKey] = input.getValue();
                } else
                    result[inputId] = input.getValue()
            }
        });
    }

    return result;
}

export function formatData(_data: any) {
    return moment(_data).format('DD/MM/YYYY');
}

export function formatDataHora(_data: any) {
    return moment(_data).format('DD/MM/YYYY HH:mm:ss');
}

export function formatDataHoraSemSegundo(_data: any) {
    return moment(_data).format('DD/MM/YYYY HH:mm');
}

export function formatHora(_data: any) {
    return moment(_data).format('HH:mm');
}

export function formatDataCompare(date: any) {
    if (!date)
        return ''

    const d = new Date(date);
    return d.getFullYear() + '-' + ('0' + (d.getMonth() + 1)).slice(-2) + '-' + ('0' + d.getDate()).slice(-2);
}

export function ageCalculator(_data: any) {
    const today = new Date();
    const age = differenceInYears(today, _data);
    return age;
}

export function getSintomas(_value: any) {
    const sintomas = [
        { nome: 'Falta de ar', valor: 32 },
        { nome: 'Taquicardia', valor: 16 },
        { nome: 'Dor no peito', valor: 8 },
        { nome: 'Escurecimento', valor: 4 },
        { nome: 'Desmaios', valor: 2 },
        { nome: 'Tontura', valor: 1 }
    ];

    let sintomasPresentes = [];

    for (let i = 0; i < sintomas.length; i++) {
        if (_value >= sintomas[i].valor) {
            sintomasPresentes.push(sintomas[i].nome);
            _value -= sintomas[i].valor;
        }
    }

    return sintomasPresentes.join(', ');
}

export function getAprendizado(_value: any) {
    const formasAprendizado = [
        { nome: 'Auditiva', valor: 4 },
        { nome: 'Visual', valor: 2 },
        { nome: 'Tato', valor: 1 }
    ];

    let aprendizado = [];

    for (let i = 0; i < formasAprendizado.length; i++) {
        if (_value >= formasAprendizado[i].valor) {
            aprendizado.push(formasAprendizado[i].nome);
            _value -= formasAprendizado[i].valor;
        }
    }

    return aprendizado.join(', ');
}

export function getExerciciosRealizados(_value: any) {
    const exercicios = [
        { nome: 'Pilates', valor: 2 },
        { nome: 'Fisioterapia', valor: 1 }
    ];

    let exercicio = [];

    for (let i = 0; i < exercicios.length; i++) {
        if (_value >= exercicios[i].valor) {
            exercicio.push(exercicios[i].nome);
            _value -= exercicios[i].valor;
        }
    }

    return exercicio.join(', ');
}

export function sendMessageBitSafira(whatsapp: any, prospnome: any, prosptoken: any, auth: any) {
    let body: any = {
        whatsapp,
        texto: 'Olá ' + prospnome + '!\n\nAbaixo você recebeu um link para responder a ficha de Anamnese da VbFIT.\n\n\nhttps://crm' + getApi() + '.vbfit.com.br/anamnese-form?u_vbfit=' + auth.usuarioEmpresa.usuemptoken + '&u_prospect=' + prosptoken
    };

    auth.post('whatsapp/enviar', body).then(() => {
        toast.success('Mensagem enviada.')
    }).catch((error: any) => {
        toastError(error);
    })
}

export const ButtonCard = (props: any) => {
    return (
        <VbTooltip title={props.hint}>
            <VbButton
                {...props}
                size='sm'
                style={{ ...props.style, borderRadius: '50%' }}
                className='p-1 me-1'
            />
        </VbTooltip>
    )
}

export function intToTipoAula(value: any) {
    return value === 1 ? 'Diário' : 'Semanal';
}

export function intToDiasSemana(value: any) {
    const dias = [
        { nome: 'Sáb', valor: 64 },
        { nome: 'Sex', valor: 32 },
        { nome: 'Qui', valor: 16 },
        { nome: 'Qua', valor: 8 },
        { nome: 'Ter', valor: 4 },
        { nome: 'Seg', valor: 2 },
        { nome: 'Dom', valor: 1 },
    ];


    let diasSemana = [];

    for (let i = 0; i < dias.length; i++) {
        if (value >= dias[i].valor) {
            diasSemana.push(dias[i].nome);
            value -= dias[i].valor;
        }
    }

    diasSemana.reverse();

    return diasSemana ? diasSemana.join(', ') : '-';
}

export const formatMonetary = (valor: Number) => {
    if (String(valor) === '')
        return '';

    return valor.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });
}

export function getMesAnoAtual() {
    moment.locale('pt-br');
    return moment().format('MMMM/YYYY').toUpperCase()
}

export function setLocalStorage(key: any, e: any) {
    localStorage.setItem(key, JSON.stringify(e));
}

export function getLocalStorage(key: any) {
    const json = localStorage.getItem(key)

    if ((!json) || (json === undefined)) {
        return null;
    }

    const result = JSON.parse(json)

    return result ?? null;
}

export function convertImageToBase64(url: any) {
    return new Promise((resolve, reject) => {
        const image = new Image();

        image.crossOrigin = 'Anonymous';
        image.src = url;

        image.onload = () => {
            const canvas = document.createElement('canvas');
            canvas.width = image.width;
            canvas.height = image.height;
            const ctx: any = canvas.getContext('2d');
            ctx.drawImage(image, 0, 0);
            const base64 = canvas.toDataURL('image/png');
            resolve(base64);
        };

        image.onerror = reject;
    });
}

export function toastError(error: any) {
    console.log(error);
    const _msg = error?.response?.data?.mensagem ? error.response.data.mensagem : 'Erro ao concluir a operação.';
    toast.error(_msg, _msg);
}

export function getLabelSimNao(value: any) {
    return (
        <div>
            {value === 1 ? 'Sim' : 'Não'}
        </div>
    )
}
