import { ConsultaPadrao } from "../../../components/ConsultaPadrao";
import { getTipoTemplate, model_template_mensagem } from "../../../models";
import FilePresentIcon from '@mui/icons-material/FilePresent';
import { AnexarArquivoTemplate } from "./AnexarArquivoTemplate";
import { useRef } from "react";
import { ViewImg } from "../../../components/ImgView";
import { toast } from "react-toastify";
import CollectionsIcon from '@mui/icons-material/Collections';
import { COLOR_PRIMARY } from "../../../theme/styles";
import { InstrucoesTemplateMensagem } from "./Instrucoes";

export const TemplateMensagem = () => {
    const refConsultaPadrao = useRef<any>();
    const anexarImgRef = useRef<any>();
    const refViewImg = useRef<any>();

    function anexarImgClick(row: any) {
        anexarImgRef.current.openModal(row);
    }

    async function viewImgClick(row: any) {
        if (!row.arquivo.url)
            toast.error('Template não possui imagem anexada.')
        else {
            refViewImg.current.openModal(row.arquivo.url);
        }
    }

    const columns = [
        { field: 'templdescricao', header: 'Descrição' },
        { field: 'templconteudo', header: 'Mensagem', sortable: true, style: { width: window.innerWidth, whiteSpace: 'pre-wrap' } },
        { field: 'templtipo', header: 'Tipo', body: (e: any) => getTipoTemplate(e.templtipo) },
        { field: 'functions', style: { minWidth: '7rem', width: '7rem' } }
    ];

    function functions() {
        return ([
            { label: 'Visualizar Imagem', icon: <CollectionsIcon />, click: viewImgClick, color: COLOR_PRIMARY },
            { label: 'Anexar Imagem', icon: <FilePresentIcon />, click: anexarImgClick, color: COLOR_PRIMARY },
        ])
    }

    function instrucoes() {
        return (
            <InstrucoesTemplateMensagem />
        )
    }

    return (
        <>
            <ConsultaPadrao ref={refConsultaPadrao} model={model_template_mensagem} columns={columns} functions={functions} customheader={instrucoes} />
            <AnexarArquivoTemplate ref={anexarImgRef} onAfterSave={() => refConsultaPadrao.current.refresh()} />
            <ViewImg ref={refViewImg} />
        </>
    )
}